import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import About from "./pages/about/";
import Home from "./pages/home/"
import { TermsConditions } from "./components/TermsConditions";
import Reservation from "./pages/reservation";
import ContactUs from "./pages/contact-us";
import ThemeProvider from "./context/ThemeContext";
function App() {
  return (
    <div className="App">
      <ThemeProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" exact component={Home} />
            <Route path="/Reservation" exact component={Reservation} />
            <Route path="/ContactUs" exact component={ContactUs} />
            <Route path="/about" exact component={About} />
            <Route path="/TermsConditions" exact component={TermsConditions} />
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
