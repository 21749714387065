export const APP_BASE_URL = "https://ramenmenu.com/"
// export const APP_BASE_URL = "http://localhost:3000/"

export const RESTAURANT_API_BASE_URL = "https://ramenmenu.com/staffmember/api"
export const GET_THEME = "/global_settings.php"
export const LOCATION_HOURS = "/location_hours.php"
export const SERVICES = "/service_module.php"
export const lOCATION_TEXT = "/location.php"
export const ABOUT_TEXT = "/about_us.php"
export const MENU = "/menus.php"
export const CATEGORY = (menuId) => `/categories.php${menuId ? `?menu_id=${menuId}`: ''}`
export const DISHES = (categoryId) => `/dishes.php${categoryId ? `?category_id=${categoryId}`: ''}`
export const DISH_OPTIONS = (id)=> `/dish_options.php${id ?  `?dish_id=${id}`:''}`   
export const PLACE_ORDER = '/new_order.php'
export const CONTACT_US = '/contact_us.php'
export const CONDITIONAL_FEE = '/conditional_fee.php'


