import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, Modal } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "../../../context/ThemeContext";
import { useStyles as backendStyles } from "../../../style/BackEndStyle";
import Alert from "@material-ui/lab/Alert";
import { Button, Grid, TextareaAutosize, Typography } from "@material-ui/core";

import ProductOptions from "./ProductOptions";
import { ComboDeals } from "./ComboDeals";
import { getDishes } from "../../../api";
import { priceLogicHandler } from "./priceHandler";

function getModalStyle() {
  if (window.innerWidth < 380) {
    return {
      outline: "unset",
      top: `26%`,
      left: `27%`,
      transform: `translate(-23%, -27%)`,
      padding: 0,
    };
  } else if (window.innerWidth < 420) {
    return {
      outline: "unset",
      top: `27%`,
      left: `29%`,
      transform: `translate(-23%, -29%)`,
      padding: 0,
    };
  } else if (window.innerWidth < 1300) {
    return {
      outline: "unset",
      top: `30%`,
      left: `38%`,
      transform: `translate(-23%, -40%)`,
      padding: 0,
    };
  } else {
    return {
      outline: "unset",
      top: `42%`,
      left: `42%`,
      transform: `translate(-23%, -40%)`,
      padding: 0,
    };
  }
}

const getWidth = () => {
  if (window.innerWidth < 350) {
    return 280;
  } else if (window.innerWidth < 400) {
    return 330;
  } else if (window.innerWidth < 450) {
    return 350;
  } else if (window.innerWidth < 500) {
    return 400;
  } else if (window.innerWidth < 550) {
    return 450;
  } else if (window.innerWidth < 600) {
    return 500;
  }
  return 500;
};

const getHeight = () => {
  if (window.innerHeight < 550) {
    return 190;
  } else if (window.innerHeight < 600) {
    return 220;
  } else if (window.innerHeight < 650) {
    return 280;
  } else if (window.innerHeight < 700) {
    return 320;
  }

  return 370;

  // else if (window.innerWidth < 750) {
  // } else if (window.innerWidth < 600) {
  //   return 500
  // }
  // return 550
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: getWidth(),
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
    // "&: focus-visible ":{
    //   border: "1px solid red !important"
    // }
  },
  cartProducts: {
    overflow: "auto",
    height: getHeight(),
  },
}));

const ProductMenuBody = ({
  decreaseProductMenuQuantity,
  selectedProduct,
  increaseProductMenuQuantity,
  menuProductQuantity,
  addItemToCart,
  addComboDealToCart,
  handleClose,
  open,
  productOptions,
  setProductOptions,
  itemAddOns,
  setItemAddOns,
  instructionText,
  setComboTax,
  isStockAvaialble,
  setIsStockAvaialble
}) => {
  const [productPrice, setProductPrice] = useState(+selectedProduct?.price);
  const classes = useStyles();

  const [styles, setStyles] = useState(undefined);
  const backendclasses = backendStyles(styles)();
  const [modalStyle] = useState(getModalStyle);
  const [addOns, setAddOns] = useState([]);
  const [removedIngredients, setRemovedIngredients] = useState([]);
  const [isValid, setisValid] = useState(false);
  const [reqAlert, setReqAlert] = useState(false);
  // const [specialInstructionText, setspecialInstructionText] = useState("")

  // const instructionText = (event) => {
  //   setspecialInstructionText(event.target.value)
  // }

  const getoptionType = () => {
    if (selectedProduct.type === "Combo") {
      return (
        <ComboDeals
          productPrice={productPrice}
          setProductPrice={setProductPrice}
          backendclasses={backendclasses}
          classes={classes}
          selectedProduct={selectedProduct}
          addOns={addOns}
          setAddOns={setAddOns}
          removedIngredients={removedIngredients}
          setRemovedIngredients={setRemovedIngredients}
          setisValid={setisValid}
          setComboTax={setComboTax}
        />
      );
    } else {
      return (
        <ProductOptions
          productPrice={productPrice}
          setProductPrice={setProductPrice}
          selectedProduct={selectedProduct}
          backendclasses={backendclasses}
          classes={classes}
          addOns={addOns}
          setAddOns={setAddOns}
          removedIngredients={removedIngredients}
          setRemovedIngredients={setRemovedIngredients}
          setisValid={setisValid}
        />
      );
    }
  };

  const checkCartValidity = () => {

    if (isValid) {
      selectedProduct.type === "Combo"
        ? addComboDealToCart(addOns)
        : addItemToCart(addOns, removedIngredients);
    } else {
      setReqAlert(true);
      
      setTimeout(() => {
        setReqAlert(false);
        setIsStockAvaialble(false);
      }, 4000);
    }
  };

  const body = () =>
    open ? (
      <div style={modalStyle} className={`${classes.paper}  `}>
        <Box className="custom-productMenu-cross" align="right">
          <ClearIcon
            onClick={handleClose}
            className={backendclasses.modalCross}
            fontSize="small"
          />
        </Box>
        <Box id="simple-modal-title" flex container direction="row">
          <Box className="cart-body">
            <Grid container>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
              >
                {reqAlert || isStockAvaialble ? (
                  <Alert className="option-alert" severity="error">
                    {reqAlert ? "Please select all required options!": "Stock limit reached" }
                      
                  </Alert>
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                className="product-cart-header"
              >
                <Grid item xs={9}>
                  <Grid className="product-menu-text">
                    <Typography
                      className={backendclasses.productMenuFontColors}
                      variant="h1"
                    >
                      {selectedProduct.display_name
                        ? selectedProduct.display_name
                        : selectedProduct.name}
                    </Typography>
                    <Typography
                      className={backendclasses.productMenuFontColors}
                      variant="h2"
                    >
                      {selectedProduct.subtitle ? selectedProduct.subtitle : ""}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Typography
                    className={backendclasses.productMenuFontColors}
                    align="center"
                    variant="h2"
                  >
                    {/* { 
                          productPrice * menuProductQuantity } */}
                    {
                    priceLogicHandler(priceLogicHandler(productPrice * menuProductQuantity).totalDollers)
                        .priceInDollers
                    }
                    <sup>
                      {
                        priceLogicHandler(productPrice * menuProductQuantity)
                          .superCents
                      }
                    </sup>
                    {/* {Math.floor(productPrice * menuProductQuantity)}
                    <sup className="price-sup">
                      {Math.ceil(
                        
                        (productPrice * menuProductQuantity * 100) % 100
                      )
                        ? Math.ceil(
                          
                            (productPrice * menuProductQuantity * 100) % 100
                          )
                        : ""}
                        
                    </sup> */}
                  </Typography>
                </Grid>
                <Grid xs={12} className="product-menu-details">
                  <Typography
                    className={backendclasses.productMenuFontColors}
                    variant="h3"
                  >
                    {selectedProduct?.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} className="product-menu-addons">
                {getoptionType()}
              </Grid>
              <Grid className="product-menu-text-area" xs={12}>
                <TextareaAutosize
                  type="text"
                  style={{ outline: "unset" }}
                  //  onChange={(event) =>{getinstructionText(event)}
                  onChange={(event) => instructionText(event)}
                  className="productMenuTextArea"
                  aria-label="empty textarea"
                  placeholder="Special Instruction"
                />
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="product-menu-bts-box"
              >
                <Grid item xs={3}>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    className={`product-menu-add-remove ${backendclasses}`}
                  >
                    <Button onClick={() => decreaseProductMenuQuantity()}>
                      <RemoveIcon
                        className={backendclasses.productMenuAddRemove}
                      />
                    </Button>
                    <Button>
                      {" "}
                      <Typography
                        className={` manu-modal-btn-text ${backendclasses.productMenuFontColors}`}
                        align="center"
                        variant="h6"
                      >
                        {menuProductQuantity}
                      </Typography>
                    </Button>
                    <Button onClick={() => increaseProductMenuQuantity()}>
                      {" "}
                      <AddIcon
                        className={backendclasses.productMenuAddRemove}
                      />
                    </Button>
                  </ButtonGroup>
                </Grid>

                <Grid item xs={5} sm={3}>
                  <Button
                    className={backendclasses.productModalBtn}
                    onClick={checkCartValidity}
                  >
                    Add To Cart
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <p id="simple-modal-description"></p>
      </div>
    ) : (
      ""
    );
  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body()}
            </Modal>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default ProductMenuBody;
