import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Banner from "./components/Banner";
import OurInfo from "./components/OurInfo";
import Products from "./components/Products";
import CartScreen from "../../components/CartScreen";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyles } from "../../style/BackEndStyle";
import ProductMenu from "./components/ProductMenu";
import { getConditionalFee, placeOrder } from "../../api/index";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Box, Grid } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import moment from "moment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { priceLogicHandler } from "./components/priceHandler";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 14,
    top: 21,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    zIndex: "20",
  },
}))(Badge);

const Home = () => {
  const [cartProducts, setCartProducts] = useState([]);
  const [totalPriceInCents, setTotalPriceInCents] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setselectedProduct] = useState(undefined);
  // const [specialInstructionText, setspecialInstructionText] = useState("")
  const [alert, setAlert] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [conditionalFees, setConditionalFees] = useState(undefined);
  const [conditionalFeeInCents, setConditionalFeeInCents] = useState(0);
  const [isOrderPlaced, setIsOrderPlaced] = useState({
    reloadDishesApi: undefined,
  });
  const daysOfWeek = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

  const setFavIcon = (icon, name) => {
    var link = document.querySelector("link[rel~='icon']");
    link.href = `https://ramenmenu.com/staffmember/${icon}`;
    const title = document.querySelector("title");
    title.text = name;
  };

  useEffect(() => {
    // debugger
    const priceInCents = cartProducts.reduce(
      (accumulator, product) =>
        accumulator + product.quantity * product.price * 100,
      0
    );

    setTotalPriceInCents(priceInCents);
    if (conditionalFees && priceInCents > 0) {
      setConditionalFeeInCents(
        conditionalFees.reduce(
          (acc, fee) =>
            acc +
            totalConditionalPriceInCents(
              priceInCents,
              fee.fixed_fee,
              fee.percent_fee,
              fee.time
            ),
          0
        )
      );
    }
  }, [cartProducts]);
  useEffect(() => {
    conditionalFee();
  }, []);

  const totalConditionalPriceInCents = (
    totalPriceInCents,
    fixed_fee,
    percent_fee,
    time
  ) => {
    let isWholeDay = false;
    let isTimeApplicable = false;
    console.log(time.length);
    if (time.length === 0) {
      isWholeDay = true;
    } else {
      const dayIndex= moment().day()
      const filteredTimeArr = time.filter(elem => elem.day == daysOfWeek[dayIndex])
      filteredTimeArr.forEach((element) => {
        if (element["24_hours"] === "true") {
          isWholeDay = true;
        } else {
          const hour = moment().hour();
          const min = moment().minute();
          const [openHour, openMin] = element.open_time.split(":");
          const [closeHour, closeMin] = element.close_time.split(":");
          if (
            (openHour < hour || (openHour == hour && openMin < min)) &&
            (closeHour > hour || (closeHour == hour && closeMin > min))
          ) {
            isTimeApplicable = true;
          }
        }
      });
    }
    var conditionalPercent = 0;
    var conditionalPriceInCents = 0;
    if (isWholeDay || isTimeApplicable) {
      if (percent_fee !== "") {
        conditionalPercent = (totalPriceInCents / 100) * percent_fee;
      }
      if (fixed_fee !== "") {
        conditionalPriceInCents = fixed_fee * 100;
      }
    }
    return conditionalPriceInCents + conditionalPercent;
  };

  const updateCart = (productIndex, addOnIndex, actionIndex) => {
    if (actionIndex === 0) {
      setCartProducts((products) => {
        if (products[productIndex].addon[addOnIndex].addon_qty > 1) {
          products[productIndex].addon[addOnIndex].addon_qty -= 1;
          products[productIndex].price -=
            products[productIndex].addon[addOnIndex].addon_opt_price;
        }
        return [...products];
      });
    } else if (actionIndex === 1) {
      setCartProducts((products) => {
        products[productIndex].price -=
          products[productIndex].addon[addOnIndex].addon_opt_price *
          products[productIndex].addon[addOnIndex].addon_qty;
        products[productIndex].addon.splice(addOnIndex, 1);
        return [...products];
      });
    } else {
      setCartProducts((products) => {
        products[productIndex].addon[addOnIndex].addon_qty += 1;
        products[productIndex].price +=
          products[productIndex].addon[addOnIndex].addon_opt_price;
        return [...products];
      });
    }
  };

  const updateComboCart = (
    dealIndex,
    productIndex,
    addOnIndex,
    actionIndex
  ) => {
    if (actionIndex === 0) {
      setCartProducts((products) => {
        if (
          products[dealIndex].dishes[productIndex].addon[addOnIndex].addon_qty >
          1
        ) {
          products[dealIndex].dishes[productIndex].addon[
            addOnIndex
          ].addon_qty -= 1;
          products[dealIndex].dishes[productIndex].price -=
            products[dealIndex].dishes[productIndex].addon[
              addOnIndex
            ].addon_opt_price;
          products[dealIndex].price -=
            products[dealIndex].dishes[productIndex].addon[
              addOnIndex
            ].addon_opt_price;
        }
        return [...products];
      });
    } else if (actionIndex === 1) {
      setCartProducts((products) => {
        if (
          products[dealIndex].dishes[productIndex].addon[addOnIndex] !==
          "ingredient"
        ) {
          products[dealIndex].dishes[productIndex].price -=
            products[dealIndex].dishes[productIndex].addon[addOnIndex]
              .addon_opt_price *
            products[dealIndex].dishes[productIndex].addon[addOnIndex]
              .addon_qty;
        }
        products[dealIndex].dishes[productIndex].addon.splice(addOnIndex, 1);
        return [...products];
      });
    } else {
      setCartProducts((products) => {
        products[dealIndex].dishes[productIndex].addon[
          addOnIndex
        ].addon_qty += 1;
        products[dealIndex].dishes[productIndex].price +=
          products[dealIndex].dishes[productIndex].addon[
            addOnIndex
          ].addon_opt_price;
        products[dealIndex].price +=
          products[dealIndex].dishes[productIndex].addon[
            addOnIndex
          ].addon_opt_price;
        return [...products];
      });
    }
  };

  const placeUserOrder = async () => {
    if (cartProducts.length > 0) {
      setIsCartOpen(false);

      const orderTax = cartProducts.reduce((cartAcc, product) => {
        return (
          cartAcc +
          product.tax.reduce((productAcc, tax, taxIndex) => {
            let totalTax = 0;
            if (tax.compound == "on") {
              const filteredCompound = product.tax.filter(
                (elem, index) => elem.compound == "on" && index < taxIndex
              );
              const accumulatedAmount = filteredCompound.reduce(
                (acc, item, currentIndex) => acc + (acc * item.rate) / 100,
                product.price * product.qty
              );
              totalTax = (accumulatedAmount * tax.rate) / 100;
            } else {
              totalTax = ((product.price * product.qty) / 100) * tax.rate;
            }
            return productAcc + totalTax;
          }, cartAcc)
        );
      }, 0);

      const amount =
        cartProducts.reduce(
          (accumulator, product) =>
            accumulator + product.quantity * product.price,
          conditionalFeeInCents / 100
        ) + orderTax;
      const body = {
        table_no: 1,
        customer_name: "abc",
        order_amount: Number.parseFloat(amount).toFixed(2),
        note: "",
        order_dishes: [...cartProducts],
      };
      const res = await placeOrder(body);
      setCartProducts([]);
      isOrderPlaced.reloadDishesApi();
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  };

  const conditionalFee = async () => {
    const { data } = await getConditionalFee();
    setConditionalFees(data);
  };

  // const instructionText = (event) => {
  //   setspecialInstructionText(event.target.value)
  // }

  const selectProduct = (product) => {
    if (product.stock != null && product.stock === "0") {
    } else {
      setselectedProduct(product);
      setOpen(true);
    }
  };
  const addToCart = (product, menuProductQuantity) => {
    setCartProducts([
      ...cartProducts,
      { ...product, quantity: menuProductQuantity },
    ]);
    setselectedProduct({});
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const increaseProductQuantity = (index) => {
    let temporaryarray = [...cartProducts];
    temporaryarray[index]["quantity"] += 1;
    setCartProducts(temporaryarray);
  };

  const decreaseProductQuantity = (index) => {
    let temporaryarray = [...cartProducts];
    if (temporaryarray[index]["quantity"] === 1) {
      removeFromCart(index);
    } else {
      temporaryarray[index]["quantity"] -= 1;
      setCartProducts(temporaryarray);
    }
  };

  const removeFromCart = (index) => {
    setCartProducts([
      ...cartProducts.slice(0, index),
      ...cartProducts.slice(index + 1),
    ]);
  };

  const getProductMenu = () =>
    open ? (
      <ProductMenu
        open={open}
        handleClose={handleClose}
        selectedProduct={selectedProduct}
        addToCart={addToCart}
      />
    ) : (
      <div></div>
    );

  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setFavIcon(data.favicon_image, data.general_name);
        setStyles(data);
        return (
          <div className={classes.Bgcolor}>
            <Box className={`add-to-cart-btn ${isCartOpen ? "hidden" : ""}`}>
              <Box className="custom-cart-btn">
                <StyledBadge
                  badgeContent={cartProducts.length}
                  color="secondary"
                >
                  {cartProducts.length > 0 ? (
                    <ShoppingCartIcon
                      style={{ color: "#EAEAEA" }}
                      onClick={() => setIsCartOpen(true)}
                      className="home-cart"
                      fontSize="large"
                    />
                  ) : (
                    <ShoppingCartIcon
                      style={{ color: "#EAEAEA" }}
                      className="home-cart"
                      fontSize="large"
                    />
                  )}
                </StyledBadge>
              </Box>
            </Box>
            {isCartOpen && (
              <CartScreen
                open={isCartOpen}
                setOpen={setIsCartOpen}
                cartProducts={cartProducts}
                increaseProductQuantity={increaseProductQuantity}
                decreaseProductQuantity={decreaseProductQuantity}
                removeFromCart={removeFromCart}
                totalPriceInCents={totalPriceInCents}
                placeUserOrder={placeUserOrder}
                updateCart={updateCart}
                updateComboCart={updateComboCart}
                conditionalFees={conditionalFees}
                setTotalPriceInCents={setTotalPriceInCents}
                totalConditionalPriceInCents={totalConditionalPriceInCents}
                conditionalFeeInCents={conditionalFeeInCents}
              />
            )}

            {getProductMenu()}
            {alert ? (
              <div>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  className="alert"
                >
                  <Alert severity="success">Your Order has been placed</Alert>
                </Grid>
              </div>
            ) : (
              <div></div>
            )}
            <Banner />

            {/* <Category />
          <Offers/> */}
            {/* <MenuDropDown/> */}
            <Products
              selectProduct={selectProduct}
              setIsOrderPlaced={setIsOrderPlaced}
            />
            {data.hide_info === "off" ? <OurInfo /> : <div></div>}
            <Footer />

            {/* {priceLogicHandler(12.2).dollers} */}
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Home;
