import React, { useState } from "react";
import {
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardMedia,
  CardContent,
  Button,
  Box,
} from "@material-ui/core";

import { ThemeContext } from "../../../context/ThemeContext";
import { useStyles } from "../../../style/BackEndStyle";
import { priceLogicHandler } from "./priceHandler";

const Product = ({
  product: {
    display_name,
    subtitle,
    name,
    description,
    image,
    price,
    tags = {},
    stock,

  },
  
}) => {
  const getTags = () =>
    tags &&
    tags.map((tags) => (
      <div className="tags-box">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: tags.tag_color }}
          className="product-icon-tag"
        >
          {tags.icon_image ? (
            <img
              className="tags-ions-img"
              src={"https://ramenmenu.com/staffmember/" + tags.icon_image}
              alt=""
            />
          ) : (
            ""
          )}
          <Typography className="product-icons-tags" variant="h6">
            {tags.tag_text ? tags.tag_text : tags.name}
          </Typography>
        </Grid>
      </div>
    ));

  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Grid item xs={12} md={4} lg={3} sm={4} className="product-box">
            <Button aria-controls="simple-menu" aria-haspopup="true">
              <Card
                className={classes.productRoot}
                // onClick={() => selectProduct(product)}
              >
                <CardActionArea>
                  <div>
                    {stock <= 0 && stock ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="left"
                        alignItems="left"
                        className="no-stocks-text"
                        xs={4}
                      >
                        {" "}
                        <h1>Out Of Stock</h1>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    <CardMedia
                      className={classes.productMedia}
                      image={"https://ramenmenu.com/staffmember/" + image}
                    />
                  </div>
                  <CardContent className="product-container">
                    <Box className="product-titles">
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.productText}
                        align="left"
                      >
                        {display_name ? display_name : name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={` product-sub-title ${classes.productText}`}
                        align="left"
                      >
                        {subtitle ? subtitle : ""}
                      </Typography>
                    </Box>
                    <Typography
                      className="product-description-text"
                      align="left"
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      className="product-title"
                    >
                      {description}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-end"
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        xs={8}
                      >
                        {getTags()}
                      </Grid>
                      <Grid xs={4} className="product-price-tag">
                        <Typography
                          style={{
                            align: "right",
                            fontWeight: "100",
                            fontSize: "20px",
                            color: "black",
                          }}
                          variant="subtitle1"
                        >
                          {
                            priceLogicHandler(
                              priceLogicHandler(price).totalDollers
                            ).priceInDollers
                          }
                          <sup>{priceLogicHandler(price).superCents}</sup>
                          {/* {Math.floor(price)}
                          <sup style={{ fontSize: "15px" }}>
                            {Math.floor((price * 100) % 100)
                              ? Math.floor((price * 100) % 100)
                              : ""}
                          </sup> */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Button>
          </Grid>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default Product;
