import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Container, Grid } from "@material-ui/core";
import { getMenu } from "../../../api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function MenuDropDown({ getMenuItems }) {
  useEffect(() => {
    getMenuItem();
  }, []);

  const classes = useStyles();
  const [age, setAge] = useState("");
  const [menuItems, setMenuItems] = useState(undefined);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const getMenuItem = async () => {
    const { data } = await getMenu();
    setMenuItems(data);
  };

  return (
    <div>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormControl className={classes.formControl}>
            <InputLabel style={{paddingLeft:"70px",fontSize:"20px",}} shrink id="demo-simple-select-placeholder-label-label">
              MENU
            </InputLabel>
            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={age}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
            >
              {" "}
              <MenuItem value="">
                <em>Select your Menu</em>
              </MenuItem>
              {menuItems &&
                menuItems.map(({ id, name, display_name, description }) => (
                  <MenuItem
                    onClick={() => getMenuItems(id)}
                    key={id}
                    value={id}
                  >
                    {display_name ? display_name : name}
                  </MenuItem>
                ))}
            </Select>
            {/* <FormHelperText></FormHelperText> */}
          </FormControl>
        </Grid>
      </Container>
    </div>
  );
}
