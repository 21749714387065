import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ClearIcon from "@material-ui/icons/Clear";
import { useStyles as backendStyles } from "../style/BackEndStyle";
import { ThemeContext } from "../context/ThemeContext";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { priceLogicHandler } from "../pages/home/components/priceHandler";
import { getDishesItems } from "../pages/home/components/Products";

function getModalStyle() {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 57;
  const left = 57;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: 0,
    outline: "unset",
  };
}

const getWidth = () => {
  if (window.innerWidth < 350) {
    return 280;
  } else if (window.innerWidth < 400) {
    return 300;
  } else if (window.innerWidth < 450) {
    return 350;
  } else if (window.innerWidth < 500) {
    return 400;
  } else if (window.innerWidth < 550) {
    return 450;
  } else if (window.innerWidth < 600) {
    return 475;
  }
  return 400;
};

const getHeight = () => {
  if (window.innerHeight < 550) {
    return 190;
  } else if (window.innerHeight < 600) {
    return 220;
  } else if (window.innerHeight < 650) {
    return 280;
  } else if (window.innerHeight < 700) {
    return 320;
  }

  return 370;

  // else if (window.innerWidth < 750) {
  // } else if (window.innerWidth < 600) {
  //   return 500
  // }
  // return 550
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: getWidth(),
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
  },
  cartProducts: {
    overflow: "auto",
    height: getHeight(),
  },
  addOnText: {
    fontSize: "16px",
    fontWeight: "200",
  },
  comboDish: {
    marginTop: "5px",
  },
}));

const CartScreen = ({
  cartProducts,
  increaseProductQuantity,
  decreaseProductQuantity,
  removeFromCart,
  totalPriceInCents,
  placeUserOrder,
  open,
  setOpen,
  updateCart,
  updateComboCart,
  conditionalFees,
  setTotalPriceInCents,
  totalConditionalPriceInCents,
  conditionalFeeInCents,
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [styles, setStyles] = useState(undefined);
  const backendclasses = backendStyles(styles)();
  const [modalStyle] = useState(getModalStyle);
  const [isEdit, setIsEdit] = useState([]);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [tax, setTax] = useState(undefined);
  const [totalAddedToTaxFee, setTotalAddedToTaxFee] = useState(0);

  useEffect(() => {
    if (cartProducts) {
      setIsEdit(cartProducts.map((product) => false));
    }
  }, []);

  const isCartClose = () => {
    setOpen(false);
  };
  const orderplace = (isOrderPlaced) => {
    placeUserOrder();
  };

  const getIngredients = (product, index, dealIndex, isCombo) => {
    const ingredients = product.addon.filter(
      (elem) => elem.addon_type === "ingredient"
    );

    if (ingredients.length > 0) {
      return (
        <div>
          <Typography className={backendclasses.cartScreenText} variant="h6">
            Removed Ingredients
          </Typography>
          {product.addon.map((addon, addonIndex) =>
            addon.addon_type === "ingredient" ? (
              <div>
                <ul>
                  <li>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {addon?.addon_name}
                      {((!isCombo && isEdit[index]) ||
                        (isCombo && isEdit[dealIndex])) && (
                        <ClearIcon
                          onClick={() =>
                            isCombo
                              ? updateComboCart(dealIndex, index, addonIndex, 1)
                              : updateCart(index, addonIndex, 1)
                          }
                          color="error"
                          fontSize="small"
                        />
                      )}
                    </Grid>
                  </li>
                </ul>
              </div>
            ) : (
              <div></div>
            )
          )}
        </div>
      );
    }
    return <div></div>;
  };

  const handleEditOption = (index) => {
    setIsEdit((edit) => {
      const updated = edit.map((option) => false);
      updated[index] = true;
      return [...updated];
    });
  };

  const getCartProduct = (product, index, dealIndex = 0, isCombo = false) => {
    return (
      <div key={index}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="cart-product-box"
        >
          <Grid item xs={1}>
            <Box className="cart-input">
              <Typography
                className={backendclasses.cartScreenText}
                variant="h6"
              >
                {product.qty}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={9}>
            <Box className="cart-product">
              <Typography
                className={backendclasses.cartScreenText}
                align="left"
                variant="h3"
              >
                {product.display_name ? product.display_name : product.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            {!isCombo && (
              <Box className="cart-price">
                <Typography
                  className={backendclasses.cartScreenText}
                  variant="h6"
                >
                  {
                    priceLogicHandler(
                      priceLogicHandler(product.price * product.qty)
                        .totalDollers
                    ).priceInDollers
                  }
                  <sup>
                    {priceLogicHandler(product.price * product.qty).superCents}
                  </sup>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid xs={1}></Grid>

          <Grid container xs={11} className="addOn-box">
            <Grid item xs={11}></Grid>

            <Grid item xs={11} className={backendclasses.cartScreenText}>
              {product &&
                product.addon.map((addon, addonIndex) =>
                  addon.addon_type === "addon" ? (
                    <div>
                      <ul>
                        <li>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            className="cart-add-ons-box"
                          >
                            {addon?.addon_name}

                            <Typography
                              variant="h6"
                              className="cart-product-qty"
                            >{`${
                              addon.addon_opt_price
                                ? "$" + addon.addon_opt_price + " x "
                                : ""
                            }${addon.addon_qty} `}</Typography>

                            {((!isCombo && isEdit[index]) ||
                              (isCombo && isEdit[dealIndex])) &&
                              (addon?.addon_isMultiQty ? (
                                <ButtonGroup
                                  color="primary"
                                  aria-label="outlined primary button group"
                                  className="car-screen-btns"
                                >
                                  <Button
                                    onClick={() =>
                                      isCombo
                                        ? updateComboCart(
                                            dealIndex,
                                            index,
                                            addonIndex,
                                            0
                                          )
                                        : updateCart(index, addonIndex, 0)
                                    }
                                  >
                                    <RemoveIcon
                                      className={
                                        backendclasses.productMenuAddRemove
                                      }
                                      fontSize="small"
                                    />
                                  </Button>

                                  <Button
                                    onClick={() =>
                                      isCombo
                                        ? updateComboCart(
                                            dealIndex,
                                            index,
                                            addonIndex,
                                            1
                                          )
                                        : updateCart(index, addonIndex, 1)
                                    }
                                  >
                                    <ClearIcon color="error" fontSize="small" />
                                  </Button>
                                  <Button
                                    onClick={() =>
                                      isCombo
                                        ? updateComboCart(
                                            dealIndex,
                                            index,
                                            addonIndex,
                                            2
                                          )
                                        : updateCart(index, addonIndex, 2)
                                    }
                                  >
                                    <AddIcon
                                      fontSize="small"
                                      className={
                                        backendclasses.productMenuAddRemove
                                      }
                                    />
                                  </Button>
                                </ButtonGroup>
                              ) : (
                                <div>
                                  {" "}
                                  <ClearIcon
                                    onClick={() =>
                                      isCombo
                                        ? updateComboCart(
                                            dealIndex,
                                            index,
                                            addonIndex,
                                            1
                                          )
                                        : updateCart(index, addonIndex, 1)
                                    }
                                    color="error"
                                    fontSize="small"
                                  />
                                </div>
                              ))}
                          </Grid>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              <div>{getIngredients(product, index, dealIndex, isCombo)}</div>
            </Grid>

            {!isCombo && (
              <Grid item xs={5} className="addon-edit-remove">
                <a
                  href
                  onClick={() => handleEditOption(index)}
                  className={backendclasses.editRemoveBtn}
                >
                  Edit
                </a>
                <a
                  href
                  onClick={() => removeFromCart(index)}
                  className={backendclasses.editRemoveBtn}
                >
                  Remove
                </a>
              </Grid>
            )}
          </Grid>

          {!isCombo && <hr className="cartscreen-hr" />}
        </Grid>
      </div>
    );
  };

  const getCartComboProduct = (deal, dealIndex) => {
    return (
      <Grid className={classes.comboDish} container direction="row">
        <Grid item xs={1}>
          <Box className="cart-input">
            <Typography className={backendclasses.cartScreenText} variant="h6">
              {deal.qty}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box className="cart-product">
            <Typography
              className={backendclasses.cartScreenText}
              align="left"
              variant="h3"
            >
              {deal.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className="cart-price">
            <Typography className={backendclasses.cartScreenText} variant="h6">
              {
                priceLogicHandler(
                  priceLogicHandler(deal.price * deal.qty).totalDollers
                ).priceInDollers
              }
              <sup>{priceLogicHandler(deal.price * deal.qty).superCents}</sup>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {deal.dishes.map((product, index) =>
            getCartProduct(product, index, dealIndex, true)
          )}
        </Grid>
        <Grid xs={1}></Grid>
        <Grid item xs={5} className="addon-edit-remove">
          <a
            href
            onClick={() => handleEditOption(dealIndex)}
            className={backendclasses.editRemoveBtn}
          >
            Edit
          </a>
          <a
            href
            onClick={() => removeFromCart(dealIndex)}
            className={backendclasses.editRemoveBtn}
          >
            Remove
          </a>
        </Grid>
        <hr className="cartscreen-hr" />
      </Grid>
    );
  };
  var totalAddedConditionalFee = 0;
  const getTotalConditionalPrice = (
    fixed_fee,
    percent_fee,
    time,
    name,
    disable_tax
  ) => {
    const totalConditionalPrice = totalConditionalPriceInCents(
      totalPriceInCents,
      fixed_fee,
      percent_fee,
      time,
      disable_tax
    );
    if (disable_tax === "") {
      totalAddedConditionalFee =
        totalAddedConditionalFee + totalConditionalPrice;
    }

    return totalConditionalPrice > 0 ? (
      <div>
        <Grid item>
          <Grid container className="cart-billing-item">
            <Grid item xs={9}>
              <Typography
                className={backendclasses.cartScreenText}
                variant="h6"
              >
                {`${name}  (${fixed_fee > 0 ? "$" + fixed_fee : ""} ${
                  fixed_fee > 0 && percent_fee > 0 ? "+" : ""
                }  ${percent_fee > 0 ? percent_fee + "%" : ""})`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={backendclasses.cartScreenText}
                variant="h6"
              >
                {
                  priceLogicHandler(
                    priceLogicHandler(totalConditionalPrice / 100).totalDollers
                  ).priceInDollers
                }
                <sup>
                  {priceLogicHandler(totalConditionalPrice / 100).superCents}
                </sup>
              </Typography>
            </Grid>
          </Grid>
          <hr className="cartscreen-price-hr" />{" "}
        </Grid>
      </div>
    ) : (
      <div></div>
    );
  };

  var totalAdededTax = 0;
  const getTaxAmount = (taxes, tax, taxIndex, data) => {
    let totalTax = 0;
    var percentAgeValue;
    // var TaxPrice = tax.calculatedAmount;
    percentAgeValue = priceLogicHandler(
      ((tax.calculatedAmount + totalAddedConditionalFee / 100) / 100) * tax.rate
    ).totalDollers;
    totalTax = priceLogicHandler(
      priceLogicHandler(
        priceLogicHandler(tax.calculatedAmount + totalAddedConditionalFee / 100)
          .totalDollers * percentAgeValue
      ).totalDollers /
        priceLogicHandler(
          priceLogicHandler(
            tax.calculatedAmount + totalAddedConditionalFee / 100
          ).totalDollers + percentAgeValue
        ).totalDollers
    ).totalDollers;

    if (data.data.general_tax_in_price === "yes") {
      tax.calculatedTax = totalTax;
    } else {
      tax.calculatedTax = percentAgeValue;
      totalTax = percentAgeValue;
    }
    if (tax.compound == "on" && data.data.general_tax_in_price === "no") {
      const filteredCompound = taxes.filter(
        (elem, index) => elem.compound == "on" && index < taxIndex
      );
      const totalCompoundTax = filteredCompound.reduce((acc, elem, index) => {
        return acc + (elem.calculatedTax / 100) * tax.rate;
      }, totalTax);
      totalTax = totalCompoundTax;
    }
    totalAdededTax = totalAdededTax + totalTax;

    if (data.data.general_tax_in_price === "no") {
      tax.calculatedTax = totalTax;
      setTax(totalAdededTax);
    }

    return (
      <div>
        {
          priceLogicHandler(priceLogicHandler(totalTax).totalDollers)
            .priceInDollers
        }
        <sup>{priceLogicHandler(totalTax).superCents}</sup>
      </div>
    );
  };

  const calculateTax = (data) => {
    const taxes = [];
    cartProducts.forEach((product) => {
      if (product.is_combo) {
        const dishTaxes = [];
        product.dishes.forEach((dish) => {
          if (dish.tax !== "") {
            dish.tax?.forEach((tax) => {
              const taxIndex = dishTaxes.findIndex(
                (elem) => elem.id === tax.id
              );
              if (taxIndex === -1) {
                dishTaxes.push({ ...tax });
              }
            });
          }
        });
        dishTaxes.forEach((tax) => {
          const taxIndex = taxes.findIndex((elem) => elem.id === tax.id);

          if (taxIndex === -1) {
            const calculatedAmount = priceLogicHandler(
              product.price * product.qty
            ).totalDollers;
            taxes.push({ ...tax, calculatedAmount });
          } else {
            const calculatedAmount =
              priceLogicHandler(product.price * product.qty).totalDollers +
              priceLogicHandler(taxes[taxIndex].calculatedAmount).totalDollers;
            taxes[taxIndex] = { ...taxes[taxIndex], calculatedAmount };
          }
        });
      } else {
        if (product.tax !== "") {
          product.tax?.forEach((tax) => {
            const taxIndex = taxes.findIndex((elem) => elem.id === tax.id);

            if (taxIndex === -1) {
              const calculatedAmount = priceLogicHandler(
                product.price * product.qty
              ).totalDollers;
              taxes.push({ ...tax, calculatedAmount });
            } else {
              const calculatedAmount =
                priceLogicHandler(product.price * product.qty).totalDollers +
                priceLogicHandler(taxes[taxIndex].calculatedAmount)
                  .totalDollers;
              taxes[taxIndex] = { ...taxes[taxIndex], calculatedAmount };
            }
          });
        }
      }
    });

    //let sum = [];

    return (
      <div>
        {taxes &&
          taxes.map((tax, taxIndex) => (
            <div key={tax.id}>
              <Grid item>
                <Grid container className="cart-billing-item">
                  <Grid item xs={9}>
                    <Typography
                      className={backendclasses.cartScreenText}
                      variant="h6"
                    >
                      {` ${tax.name} (${tax.rate}%)  ${
                        data.data.general_tax_in_price === "yes"
                          ? "inc. in price"
                          : "excl. in price"
                      }  `}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={backendclasses.cartScreenText}
                      variant="h6"
                    >
                      {getTaxAmount(taxes, tax, taxIndex, data)}
                    </Typography>
                  </Grid>

                  {tax.show_message ? (
                    <Grid item xs={12}>
                      <Typography
                        className={backendclasses.cartScreenText}
                        variant="h5"
                      >
                        {tax.message ? tax.message : ""}
                      </Typography>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
                <hr className="cartscreen-price-hr" />{" "}
              </Grid>
            </div>
          ))}
      </div>
    );
  };

  const body = (data) => (
    <div style={modalStyle} className={classes.paper}>
      <div id="simple-modal-title" flex container direction="row">
        <Box className="cart-heading">
          <Box className="cart-heading-bg">
            <Grid container direction="row">
              <Grid item xs={11}>
                <Typography
                  className={backendclasses.cartScreenText}
                  align="left"
                  variant="h2"
                  id="simple-modal-title"
                >
                  CART
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <div align="right">
                  <ClearIcon
                    onClick={isCartClose}
                    className={`${backendclasses.cartScreenPrimaryColor} custom-cart-screen-cross`}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box className="cart-titles">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={2} sm={1}>
                <Typography
                  className={backendclasses.cartScreenText}
                  variant="h6"
                >
                  Qty
                </Typography>
              </Grid>
              <Grid item xs={8} sm={9}>
                <Typography
                  className={backendclasses.cartScreenText}
                  variant="h6"
                >
                  Item
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2}>
                <Typography
                  className={backendclasses.cartScreenText}
                  variant="h6"
                >
                  Price
                </Typography>
              </Grid>
              <Divider width="95%" />
            </Grid>
          </Box>
        </Box>
        <Box className="cart-heading">
          <Box className={classes.cartProducts}>
            {cartProducts &&
              cartProducts.map((product, index) =>
                product.is_combo
                  ? getCartComboProduct(product, index)
                  : getCartProduct(product, index)
              )}
          </Box>
          <Box>
            <Grid container direction="column" className="cart-billing">
              <Box className="total-tax-fees">
                <Divider />
                <Grid container className="cart-billing-item">
                  <Grid item xs={9}>
                    <Typography
                      className={backendclasses.cartScreenText}
                      variant="h6"
                    >
                      Cart
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      className={backendclasses.cartScreenText}
                      variant="h6"
                    >
                      {
                        priceLogicHandler(
                          priceLogicHandler(totalPriceInCents / 100)
                            .totalDollers
                        ).priceInDollers
                      }
                      <sup>
                        {priceLogicHandler(totalPriceInCents / 100).superCents}
                      </sup>
                    </Typography>
                  </Grid>
                  <hr className="cartscreen-price-hr" />
                </Grid>

                {conditionalFees &&
                  conditionalFees.map((fee) =>
                    getTotalConditionalPrice(
                      fee.fixed_fee,
                      fee.percent_fee,
                      fee.time,
                      fee.name,
                      fee.disable_tax
                    )
                  )}

                {calculateTax(data)}
              </Box>

              <Grid item sm={12}>
                <Grid container className="cart-billing-item-total">
                  <Grid item xs={9}>
                    <Typography
                      className={backendclasses.cartScreenText}
                      variant="h6"
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {cartProducts.length > 0 ? (
                      <Typography
                        className={backendclasses.cartScreenText}
                        variant="h6"
                      >
                        {data.data.general_tax_in_price === "yes"
                          ? priceLogicHandler(
                              priceLogicHandler(
                                (totalPriceInCents + conditionalFeeInCents) /
                                  100
                              ).totalDollers
                            ).priceInDollers
                          : priceLogicHandler(
                              priceLogicHandler(
                                (totalPriceInCents +
                                  conditionalFeeInCents +
                                  priceLogicHandler(tax ? tax : 0)
                                    .totalPriceInCenets) /
                                  100
                              ).totalDollers
                            ).priceInDollers}
                        <sup>
                          {data.data.general_tax_in_price === "yes"
                            ? priceLogicHandler(
                                (totalPriceInCents + conditionalFeeInCents) /
                                  100
                              ).superCents
                            : priceLogicHandler(
                                (totalPriceInCents +
                                  conditionalFeeInCents +
                                  priceLogicHandler(tax ? tax : 0)
                                    .totalPriceInCenets) /
                                  100
                              ).superCents}
                        </sup>
                      </Typography>
                    ) : (
                      <Typography>0</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Divider />
        <Box className="cart-btn-style">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={`cart-btn`}
          >
            <Button
              className={backendclasses.orderPlacedBtn}
              onClick={orderplace}
            >
              Place Your Order
            </Button>
          </Grid>
        </Box>
      </div>
      <p id="simple-modal-description"></p>
    </div>
  );

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <div>
            <Modal
              open={open}
              onClose={isCartClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body({ data })}
            </Modal>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default CartScreen;
