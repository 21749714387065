/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/alt-text */
import { Box, Container, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../../style/mainStyle.css";
import Footer from "../../components/Footer";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyles } from "../../style/BackEndStyle";
import { getAboutText } from "../../api";
import Banner from "../home/components/Banner";

const About = () => {
  const [aboutText, setAboutText] = useState(undefined);

  const [styles, setStyles] = useState(undefined);

  useEffect(() => {
    getAboutTextData();
  }, []);
  
  const getAboutTextData = async () => {
    const { data } = await getAboutText();

    const aboutText = {};
    data.forEach((element) => {
      aboutText[element.key_pair] = element.value;
    });

    setAboutText(aboutText);
  };

  const classes = useStyles(styles)();
  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Box className={classes.Bgcolor}>
            <Banner />
            <Container>
              <Grid
                container
                style={{ marginTop: "70px", marginBottom: "110px" }}
              >
                <Grid item xs={12} lg={6}>
                  <img
                    src={
                      "https://ramenmenu.com/staffmember/" +
                      aboutText?.about_us_image_section_one
                    }
                    width="90%"
                    className="about-img"
                  />
                </Grid>

                <Grid container spacing={3} item xs={12} lg={6}>
                  <Box className="AboutImgContent">
                    <Typography
                      className={classes.primaryText}
                      align="left"
                      variant="h2"
                    >
                      {aboutText?.about_us_heading_section_one}
                    </Typography>
                    <Typography
                      className={classes.normalFont}
                      align="left"
                      variant="h3"
                    >
                      {aboutText?.about_us_text_section_one}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box className="AboutImgContent">
                    <Typography
                      className={classes.primaryText}
                      align="left"
                      variant="h2"
                    >
                      {aboutText?.about_us_heading_section_two}
                    </Typography>
                    <Typography
                      className={classes.normalFont}
                      align="left"
                      variant="h3"
                    >
                      {aboutText?.about_us_text_section_two}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <img
                    src={
                      "https://ramenmenu.com/staffmember/" +
                      aboutText?.about_us_image_section_two
                    }
                    width="90%"
                    className="about-img"
                  />
                </Grid>
              </Grid>
            </Container>
            <Footer />
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default About;
