import React, { useState, createContext, useEffect } from "react";
import { getTheme } from "../api";

console.log('hellooo')
export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const getThemeData = async () => {
    const {data} =await getTheme();
    const themeObj = {}
      data.forEach(element => {
        themeObj[element.key_pair] = element.value
      });
    
    
    setTheme(
      themeObj
      // [
      //   { id: 1, categoryId: 1, name: "Pizza", price: { dollar: 25, cent: 50 } },
      //   { id: 2, categoryId: 1, name: "Burger", price: { dollar: 25, cent: 50 } },
      //   { id: 3, categoryId: 1, name: "Fries", price: { dollar: 25, cent: 50 } },
      //   { id: 4, categoryId: 2, name: "Deal 1", price: { dollar: 25, cent: 50 } }, 
      //   { id: 5, categoryId: 2, name: "Deal 2", price: { dollar: 25, cent: 50 } }, 
      //   { id: 6, categoryId: 3, name: "Cake", price: { dollar: 25, cent: 50 } },
      //   { id: 7, categoryId: 3, name: "Pastery", price: { dollar: 25, cent: 50 } },
      //   { id: 8, categoryId: 3, name: "Pie", price: { dollar: 25, cent: 50 } },
      // ]
    );
  };
  useEffect(() => {
    getThemeData();
  }, []);

  const [theme, setTheme] = useState(undefined);
  console.log('warn');
  if(theme){
    return (
      <ThemeContext.Provider value={theme}> {children} </ThemeContext.Provider>
    );

  }
  else  return <div></div>

};
export default ThemeProvider;
