import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import React, { useState, useEffect } from "react";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import { ThemeContext } from "../../../context/ThemeContext";
import { useStyles } from "../../../style/BackEndStyle";
import { getLocationHours, getLocationText } from "../../../api";
import moment from "moment";

const OurInfo = () => {
  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();
  const [locationOpeningHours, setLocationOpeningHours] = useState(undefined);
  const [specialHours, setSpecialHours] = useState(undefined);
  const [timeFormate, setTimeFormat] = useState("12_hours");
  const [locationText, setLocationText] = useState(undefined);

  useEffect(() => {
    getLocationHoursData();
    getlocationTextData();
  }, []);

  const getLocationHoursData = async () => {
    const {
      data: { location_opening_hour, special_hours },
    } = await getLocationHours();
    setLocationOpeningHours(location_opening_hour);
    setSpecialHours(special_hours);
  };
  const getlocationTextData = async () => {
    const { data } = await getLocationText();
    const locationObj = {};

    data.forEach((element) => {
      locationObj[element.key_pair] = element.value;
    });

    setLocationText(locationObj);
  };

  const getOpenAndCloseTime = (
    timeFormat,
    open_time,
    close_time,
    specialHour
  ) => {
    if (specialHour === "on") {
      return "24Hrs";
    } else if (specialHour === "" && (close_time === "" || open_time === "")) {
      return "invlid open & close time";
    } else {
      let final_format = "";
      if (timeFormat === "12_hours") {
        final_format = "hh:mm A";
      } else {
        final_format = "HH:mm";
      }
      let OPEN_TIME = new moment(open_time, "h:m").format(final_format);
      let CLOSE_TIME = new moment(close_time, "h:m").format(final_format);
      return `${OPEN_TIME} - ${CLOSE_TIME}`;
    }
  };

  const getLocationOpenAndCloseTime = (
    timeFormat,
    open_time,
    close_time,
    location
  ) => {
    if (location === "on") {
      return "24Hrs";
    } else if (location === "" && (close_time === "" || open_time === "")) {
      return "invlid open & close time";
    } else {
      let final_format = "";
      if (timeFormat === "12_hours") {
        final_format = "hh:mm A";
      } else {
        final_format = "HH:mm";
      }
      let OPEN_TIME = new moment(open_time, "h:m").format(final_format);
      let CLOSE_TIME = new moment(close_time, "h:m").format(final_format);
      return `${OPEN_TIME} - ${CLOSE_TIME}`;
    }
  };

  const getFormatedDate = (date, format) => {
    // const dateArr = date.split("-");
    const formatArr = format.split("/");
    const updatedFormat = formatArr.map((elem) => elem.toUpperCase()).join("/");

    let updatedDate = new moment(date).format(updatedFormat);
    // debugger;
    // const updatedDate = formatArr
    //   .map((order) => {

    //     if (order.toUpperCase() === "Y") {
    //       return dateArr[0];
    //     } else if (order.toUpperCase() === "M") {
    //       return dateArr[1];
    //     }
    //     return dateArr[2];
    //   })
    //   .join("/");
    return updatedDate;
  };

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        setTimeFormat(data.general_timeformat);
        return (
          <Box>
            <Container>
              <Grid container>
                <Grid sm={6} item xs={12} lg={4}>
                  <Box className="opningDays">
                    <Paper className={classes.servicesBg}>
                      <ImportContactsIcon className={classes.servicesIcons} />

                      <Typography variant="h1" className={classes.popupHeading}>
                        Special Hours
                      </Typography>
                      <Box className="days">
                        {specialHours &&
                          specialHours.map((specialHour) => (
                            <div key={specialHour.id}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  className={classes.popupText}
                                  align="left"
                                  variant="h6"
                                >
                                  {/* {specialHour.date} */}
                                  {getFormatedDate(
                                    specialHour.date,
                                    data.general_date_formate
                                  )}
                                </Typography>

                                <Typography
                                  className={classes.popupText}
                                  align="right"
                                  variant="h6"
                                >
                                  {getOpenAndCloseTime(
                                    data.general_timeformat,
                                    specialHour.open_time,
                                    specialHour.close_time,
                                    specialHour["24_hours"]
                                  )}
                                </Typography>
                              </Grid>

                              <Divider />
                            </div>
                          ))}
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Box className="opningDays">
                    <Paper className={classes.servicesBg}>
                      {/* <RoomIcon className={classes.servicesIcons} /> */}

                      <Typography
                        variant="h2"
                        className={classes.popupHeadingCustom}
                      >
                        {locationText?.location_heading}
                      </Typography>
                      <Box className="location-text">
                        <Typography
                          align="left"
                          className={classes.popupText}
                          variant="h6"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: locationText?.location_text,
                            }}
                          ></div>
                          {/* {locationText?.location_text} */}
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>

                <Grid item sm={12} xs={12} lg={4}>
                  <Box className="opningDays">
                    <Paper className={classes.servicesBg}>
                      <AccessTimeIcon className={classes.servicesIcons} />

                      <Typography variant="h1" className={classes.popupHeading}>
                        OPENING TIMINGS
                      </Typography>
                      <Box className="days">
                        {locationOpeningHours &&
                          locationOpeningHours.map((location) => (
                            <div key={location.id}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  className={classes.popupText}
                                  align="left"
                                  variant="h6"
                                >
                                  {location.day}
                                </Typography>
                                <Typography
                                  ography
                                  className={classes.popupText}
                                  align="right"
                                  variant="h6"
                                >
                                  {getLocationOpenAndCloseTime(
                                    data.general_timeformat,
                                    location.open_time,
                                    location.close_time,
                                    location["24_hours"]
                                  )}
                                </Typography>
                              </Grid>
                              <Divider />
                            </div>
                          ))}
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default OurInfo;
