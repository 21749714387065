import { GET_THEME, SERVICES, LOCATION_HOURS, lOCATION_TEXT, ABOUT_TEXT, MENU, CATEGORY, DISHES, DISH_OPTIONS, PLACE_ORDER, CONTACT_US, CONDITIONAL_FEE } from "./endpoints"
import { get, post } from "./fetch"

export const getTheme = () => {
  return get({ url: GET_THEME })
}

export const getServices = () => {
  return get({ url: SERVICES })
}

export const getLocationHours = () => {
  return get({ url: LOCATION_HOURS })
}
export const getLocationText = () => {
  return get({ url: lOCATION_TEXT })
}
export const getAboutText = () => {
  return get({ url: ABOUT_TEXT })
}
export const getMenu = () => {
  return get({ url: MENU })
}
export const getCategory = (menuId) => {
  return get({ url: CATEGORY(menuId) })
}
export const getDishes = (categoryId) => {
  return get({ url: DISHES(categoryId) })
}
export const getDishOptions = (id) => {
  return get({ url: DISH_OPTIONS(id) })
}

export const placeOrder = (order) => {
  return post({ url: PLACE_ORDER, body: order })
}

export const contactUs = (body) => {
  return post({ url: CONTACT_US, body: body })
}
export const getConditionalFee = () => {
  return get({ url: CONDITIONAL_FEE })
}