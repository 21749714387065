import { Container, Grid, Box, Typography, Button } from "@material-ui/core";
import React, { useState } from "react";
import Banner from "../assets/banner.jpg";
import Header from "../../../components/header/Header";
import { ThemeContext } from "../../../context/ThemeContext";
import { useStyles } from "../../../style/BackEndStyle";
import Products from "./Products";

const Hero = () => {
  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Box
            style={
              data.web_header_hide_background_image_tint_color === "on"
                ? {
                    backgroundColor:
                      data.web_header_background_image_tint_color,
                    height: "350px",
                    width: "100%",
                  }
                : {
                    backgroundImage: `url("https://ramenmenu.com/staffmember/${data.web_header_background_image}")`,
                    backgroundSize: "cover",
                    height: "350px",
                    width: "100%",
                  }
            }
          >
            <Header />
            <Container>
              <Grid container>
                {/* <Grid item xs={12} className="hero-titles"> */}
                <Grid item xs={12} className={classes.bannerTitle}>
                  <Typography variant="h1">{data.web_header_title}</Typography>

                  <Typography variant="h2">
                    {data.web_header_subtitle}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Hero;
