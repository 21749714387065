import React, { useState, useEffect } from "react";
import ProductMenuBody from "./ProductMenuBody";
import { Box } from "@material-ui/core";

const ProductMenu = ({ handleClose, open, selectedProduct, addToCart }) => {
  const [menuProductQuantity, setmenuProductQuantity] = useState(1);
  const [itemAddOns, setItemAddOns] = useState([]);
  const [comboTax, setComboTax] = useState([]);
  const [isStockAvaialble, setIsStockAvaialble] = useState(false);

  const [specialInstructionText, setspecialInstructionText] = useState("");

  useEffect(() => {
    if (selectedProduct.type === "Combo") {
      setComboTax(selectedProduct.choice.map((choice) => []));
    }
  }, []);

  const instructionText = (event) => {
    setspecialInstructionText(event.target.value);
  };

  const addItemToCart = (addOns, removedIngredients) => {
    const item = {
      tax: selectedProduct.tax,
      item_id: selectedProduct.id,
      name: selectedProduct.display_name ? selectedProduct.display_name : selectedProduct.name,
      qty: menuProductQuantity,
      is_combo: 0,
      price: addOns.reduce((prev, options) => {
        const y =
          prev +
          options.reduce((prevVal, option) => {
            const x = prevVal + option.addon_qty * option.addon_opt_price;
            return x;
          }, 0);
        return y;
      }, +selectedProduct.price),
      note: specialInstructionText,
      addon: [
        ...addOns.reduce(
          (prev, options) => [
            ...prev,
            ...options.map((val) => ({
              addon_id: val.addon_id,
              addon_qty: val.addon_qty,
              addon_type: val.addon_type,
              addon_name: val.addon_name,
              addon_opt_price: val.addon_opt_price,
              addon_isMultiQty: val.addon_isMultiQty,
            })),
          ],
          []
        ),
        ...removedIngredients.map((val) => ({
          addon_id: val.id,
          addon_qty: 1,
          addon_type: "ingredient",
          addon_name: val.ingredient_name,
          addon_opt_price: 0,
        })),
      ],
    };
    console.log(item);
    console.log(itemAddOns);
    handleClose();
    addToCart(item, menuProductQuantity);
    // setItemAddOns()
  };

  const addComboDealToCart = (data) => {
    const dishes = data.map((dish, dishIndex) => ({
      tax: comboTax[dishIndex],
      item_id: dish.dish.id,
      qty: 1,
      name: dish.dish.name,
      price: dish.comboAddOns.reduce(
        (acc, val) =>
          val.reduce(
            (addOnAcc, currentVal) =>
              addOnAcc + currentVal.addon_qty * currentVal.addon_opt_price,
            acc
          ),
        0,
        0
      ),
      // note:specialInstructionText,
      is_combo: 1,
      addon: [
        ...dish.comboAddOns.reduce(
          (prev, options) => [
            ...prev,
            ...options.map((val) => ({
              addon_id: val.addon_id,
              addon_qty: val.addon_qty,
              addon_type: val.addon_type,
              addon_name: val.addon_name,
              addon_opt_price: val.addon_opt_price,
              addon_isMultiQty: val.addon_isMultiQty,
            })),
          ],
          []
        ),
        ...dish.removedIngredients.map((val) => ({
          addon_id: val.id,
          addon_qty: 1,
          addon_type: "ingredient",
          addon_name: val.ingredient_name,
          addon_opt_price: 0,
        })),
      ],
    }));

    const item = {
      item_id: selectedProduct.id,
      name: selectedProduct.name,
      qty: menuProductQuantity,
      is_combo: 1,
      price: dishes.reduce(
        (acc, elem) => acc + elem.price,
        +selectedProduct.price
      ),
      note: specialInstructionText,
      dishes,
      stock: selectedProduct.stock,
    };
    // console.log(item)
    // handleClose()
    addToCart(item, menuProductQuantity);
  };

  const increaseProductMenuQuantity = () => {
    let stockQuantity = parseInt(selectedProduct.stock);
    if (stockQuantity === menuProductQuantity) {
      setIsStockAvaialble(true);
      return;
    }
    setIsStockAvaialble(false);

    setmenuProductQuantity(menuProductQuantity + 1);
  };

  const decreaseProductMenuQuantity = () => {
    setIsStockAvaialble(false);
    if (menuProductQuantity === 1) {
      setmenuProductQuantity(1);
      
    } else {
      setmenuProductQuantity(menuProductQuantity - 1);
    }
  };

  return (
    <Box>
      <ProductMenuBody
        open={open}
        decreaseProductMenuQuantity={decreaseProductMenuQuantity}
        increaseProductMenuQuantity={increaseProductMenuQuantity}
        addItemToCart={addItemToCart}
        addComboDealToCart={addComboDealToCart}
        menuProductQuantity={menuProductQuantity}
        selectedProduct={selectedProduct}
        handleClose={handleClose}
        itemAddOns={itemAddOns}
        setItemAddOns={setItemAddOns}
        instructionText={instructionText}
        setComboTax={setComboTax}
        isStockAvaialble={isStockAvaialble}
        setIsStockAvaialble={setIsStockAvaialble}
      />
    </Box>
  );
};
export default ProductMenu;
