/* eslint-disable react/jsx-no-duplicate-props */
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getCategory, getDishes } from "../../../api";
import { ThemeContext } from "../../../context/ThemeContext";
import { useStyles } from "../../../style/BackEndStyle";
import MenuDropDown from "./MenuDropDown";
import Product from "./Product";

const Products = ({ selectProduct,setIsOrderPlaced }) => {
  
  const [products, setProducts] = useState(undefined);
  const [hidecategory, setHidecategory] = useState("off");
  const [menuItemsID, setMenuItemsID] = useState();
  const [categoryItems, setcategoryItems] = useState();
  const [styles, setStyles] = useState(undefined);
  const [categoryID, setCategoryID] = useState();

  const classes = useStyles(styles)();

  useEffect(() => {
    getCategoryItems();
  }, [menuItemsID]);

  useEffect(() => {
    getDishesItems();
  }, [categoryID]);

  useEffect(() => {
    setIsOrderPlaced(isOrderPlaced => {
      isOrderPlaced.reloadDishesApi = () => {
        getDishesItems();
        // debugger
      }
      return {...isOrderPlaced}
    })
  },[])

  const getCategoryItems = async () => {
    const { data } = await getCategory(menuItemsID);
    data.splice(0, 0, { id: 0, display_name: "All" });
    setcategoryItems(data);
  };

  const getDishesItems = async () => {
    const category = categoryID === 0 ? undefined : categoryID;
    const { data } = await getDishes(category);
    setProducts(data);
  };

  const productTabFun = () => {
    if (hidecategory.web_hide_categories === "off") {
      return (
        categoryItems &&
        categoryItems.map(
          ({
            id,
            name,
            display_name,
            description,
            menu_id,
            hide_unavailable,
            
          }) =>
            hide_unavailable ? (
              ""
            ) : (
              <Button
                onClick={() => setCategoryID(id)}
                key={id}
                className={`${classes.tabBtn} tab-btn   ${
                  menu_id === menuItemsID
                    ? classes.activeTabBtn
                    : classes.noActiveTabBtn
                }   `}
                // onClick={(event) => setActiveTabId(id)}
              >
                {display_name ? display_name : name}
              </Button>
            )
        )
      );
      // const productTabFun = () => {
      //   if (hidecategory.web_hide_categories === "off") {
      //     return tabs.map((tab) => (
      //       <Button
      //         key={tab.id}
      //         className={`${classes.tabBtn} tab-btn   ${
      //           tab.id === activeTabId
      //             ? classes.activeTabBtn
      //             : classes.noActiveTabBtn
      //         }   `}
      //         onClick={(event) => setActiveTabId(tab.id)}
      //       >
      //         {tab.text}
      //       </Button>
      //     ));
    }
    // else{
    //  <Button className={`${classes.tabBtn} tab-btn`}
    //  >
    //  All
    //  </Button>
    // }
  };

  const getFilteredProducts = () =>
  
    products &&
    products.map((product) => (
 
     <Box key={product.id} onClick={() => selectProduct(product)}>
        <Product product={product} />
      </Box>
    
    ));
    
  const getMenuItems = (MenuId) => {
    setMenuItemsID(MenuId);
  };

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        setHidecategory(data);

        return (
          <div>
            <Container>
              <Grid
                id="products"
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="Products-box"
              >
                <Grid item xm={12}>
                  <Box className="products-text">
                    <Typography className={classes.primaryText} variant="h1">
                      Menu　メニュー
                    </Typography>

                    {/* <Typography className={classes.normalFont} variant="h2">
                      ALL DAY FOOD SOLUTION
                    </Typography> */}
                  </Box>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <MenuDropDown getMenuItems={getMenuItems} /> */}
                </Grid>

                <Grid
                  container
                  justifyContent="space-around"
                  alignItems="flex-start"
                  direction="row"
                  item
                  xm={12}
                  spacing={12}
                >
                  {productTabFun()}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={1}
                >
                  {getFilteredProducts()}
                </Grid>
              </Grid>
            </Container>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Products;
