import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Box,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { alpha } from "@material-ui/core/styles";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyles as backendStyles } from "../../style/BackEndStyle";

const headersData = [
  {
    label: "Home",
    href: "/home",
  },
  {
    label: "About",
    href: "/about",
  },
  {
    label: "Menu",
    href: "/products",
  },
  // {
  //   label: "Reservation",
  //   href: "/reservation",
  // },
  {
    label: "Contact",
    href: "/ContactUs",
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    // backgroundColor: "#400CCC",
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  // menuButton: {
  //   fontFamily: "Open Sans, sans-serif",
  //   fontWeight: 700,
  //   size: "18px",
  //   marginLeft: "38px",

  // },

  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function NavBar() {
  const [styles, setStyles] = useState(undefined);
  const backenclasses = backendStyles(styles)();

  const { header, toolbar, drawerContainer } = useStyles();

  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);
  const getSearchBtn = (web_hide_searchbar) => {
    if (web_hide_searchbar === "off") {
      return (
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon className={backenclasses.iconsBtn} fontSize="medium" />
          </Box>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </Box>
      );
    }
  };

  const displayDesktop = (web_hide_searchbar) => {
    return (
      <Toolbar className={toolbar}>
        <div>{getMenuButtons()}</div>
        <div>{getSearchBtn(web_hide_searchbar)}</div>
      </Toolbar>
    );
  };

  const displayMobile = (web_hide_searchbar) => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
       <div>{getSearchBtn(web_hide_searchbar)}</div>
        <IconButton
          {...{
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem
            onClick={() =>
              setState((prevState) => ({ ...prevState, drawerOpen: false }))
            }
          >
            {label}
          </MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () =>
    headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            // className: menuButton,
            className: ` ${backenclasses.menuButton}`,
          }}
        >
          {label}
        </Button>
      );
    });

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <header>
            <AppBar
              color="transparent"
              position="static"
              className={header}
              style={{ boxShadow: "none" }}
            >
              {mobileView
                ? displayMobile(data.web_hide_searchbar)
                : displayDesktop(data.web_hide_searchbar)}
            </AppBar>
          </header>
        );
      }}
    </ThemeContext.Consumer>
  );
}
