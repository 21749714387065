export const priceLogicHandler = (price) => {

    var totalPriceInCenets;
    var priceInDollers;
    var superCents;
    var totalDollers;
    var a = price * 100;

    totalPriceInCenets = Math.trunc((price * 100).toFixed(0)) ;
    priceInDollers = totalPriceInCenets / 100;
    superCents = Math.trunc(totalPriceInCenets % 100) ;
    priceInDollers = Math.trunc(priceInDollers);
    totalDollers = priceInDollers + (superCents/100)
    if(superCents===0){
        superCents=""
    }
    else if (superCents < 10 && superCents > 0 ) {
        superCents = ('0') +  superCents ;
    }
    return {
        priceInDollers,
        totalPriceInCenets,
        superCents,
        totalDollers
    }


    // return totalPrice


}
