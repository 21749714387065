import React, { useState, useEffect } from "react"
import ListItem from "@material-ui/core/ListItem"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Collapse from "@material-ui/core/Collapse"
import Switch from "@material-ui/core/Switch"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import { Box } from "@material-ui/core"
import ButtonGroup from "@material-ui/core/ButtonGroup"
// import CircularProgress from "@mui/material/CircularProgress";
import { Button, Divider, Grid, List, Typography } from "@material-ui/core"

import { getDishOptions } from "../../../api"

const ProductOptions = ({
  productPrice,
  setProductPrice,
  classes,
  backendclasses,
  selectedProduct,
  addOns,
  setAddOns,
  removedIngredients,
  setRemovedIngredients,
  editMode,
  setisValid,
  isComboProduct,
  updateComboDishTax,
}) => {
  const [productOptions, setProductOptions] = useState({})
  const [optionAccordianExpension, setOptionAccordianExpension] = useState([])
  const [dishesLoader, setDishesLoader] = useState(false)

  useEffect(() => {
    console.log("editMode", editMode)
    if (selectedProduct) getOptions()
  }, [selectedProduct])

  useEffect(() => {
    if (optionAccordianExpension && optionAccordianExpension?.length > 0) {
      const isPassed = optionAccordianExpension.every((val, index) => (val.isRequired ? addOns[index]?.length > 0 : true))
      setisValid(isPassed)
    }
  }, [addOns])

  const getOptions = async () => {
    setDishesLoader(true)
    const { data } = await getDishOptions(selectedProduct.id)
    setDishesLoader(true)
    setProductOptions(data)
    if(isComboProduct){
      updateComboDishTax(data.taxes)
    }
    if (data.option_sets) {
      setOptionAccordianExpension(
        data.option_sets.map((option) => ({
          id: option.id,
          isExpanded: false,
          isRequired: option.required === "on",
          isMultiQty: option.enable_opt_qty,
        }))
      )
      if (!editMode) {
        setAddOns(data.option_sets.map((option) => []))
      }
    } else {
      setisValid(true)
    }

    setOptionAccordianExpension((expensions) => {
      expensions.push({ id: 0, isExpanded: false, isRequired: false })
      return [...expensions]
    })
  }

  const handleOptionSetAccordian = (id) => {
    const newOptionSet = optionAccordianExpension.map((option) => {
      if (option.id === id) {
        return { ...option, isExpanded: !option.isExpanded }
      }
      return { ...option, isExpanded: false }
    })
    setOptionAccordianExpension(newOptionSet)
  }

  const increaseOptionQty = ({ max_opt_required, optionSetIndex, option }) => {
    const addonIndex = addOns[optionSetIndex].findIndex((addon) => addon.addon_id === option.id)
    const maxOptions = max_opt_required === "" ? undefined : +max_opt_required
    const totalSelected = addOns[optionSetIndex].reduce((acc, addOn) => acc + addOn.addon_qty, 0)
    if (addonIndex === -1) {
      if (!maxOptions || totalSelected < maxOptions) {
        const items = [...addOns]
        items[optionSetIndex].push({
          addon_id: option.id,
          addon_qty: 1,
          addon_type: "addon",
          addon_opt_price: +option.opt_price,
          addon_name: option.opt_name,
          addon_isMultiQty: optionAccordianExpension[optionSetIndex].isMultiQty,
        })
        setAddOns([...items])
        setProductPrice((price) => {
          const optPrice = + option.opt_price
         
          return option.opt_price === "" ? price : optPrice + price
        
        })
      }
    } else {
      const items = [...addOns]
      if (!maxOptions || totalSelected < maxOptions) {
        items[optionSetIndex][addonIndex].addon_qty++
        setProductPrice((price) => {
          const optPrice = +option.opt_price
          return option.opt_price === "" ? price : optPrice + price
        })
      }
      setAddOns([...items])
    }
  }

  const decreaseOptionQty = (optionSetIndex, option, removeAt) => {
    const addonIndex = addOns[optionSetIndex].findIndex((addon) => addon.addon_id === option.id)
    if (addonIndex >= 0) {
      setAddOns((items) => {
        setProductPrice((price) => (option.opt_price === "" ? price : price - +option.opt_price))
        if (items[optionSetIndex][addonIndex].addon_qty > removeAt) {
          items[optionSetIndex][addonIndex].addon_qty--
        } else {
          items[optionSetIndex].splice(addonIndex, 1)
        }
        return [...items]
      })
    }
  }

  const handleMultiQty = ({ event, max_opt_required, optionSetIndex, option }) => {
    event.target.checked ? increaseOptionQty({ max_opt_required, optionSetIndex, option }) : decreaseOptionQty(optionSetIndex, option, 1)
  }
  const GetAddOnValue = (optionSetIndex, { id }) => {
    console.log("addOns", optionSetIndex, id, addOns)
    const val = addOns[optionSetIndex]?.find((val) => val.addon_id == id)
    return val ? val.addon_qty : 0
  }

  const handleSingleQty = ({ event, option_sets, optionSetIndex, option }) => {
    if (event.target.checked) {
      setProductPrice((price) => {
        let updatedPrice = price
        if (addOns[optionSetIndex].length > 0) {
          updatedPrice -= addOns[optionSetIndex][0].addon_opt_price
        }
        updatedPrice += +option.opt_price
        return updatedPrice
      })
      const addOnsCopy = [...addOns]
      addOnsCopy[optionSetIndex] = []
      addOnsCopy[optionSetIndex].push({
        addon_id: option.id,
        addon_qty: 1,
        addon_type: "addon",
        addon_opt_price: +option.opt_price,
        addon_name: option.opt_name,
        addon_isMultiQty: optionAccordianExpension[optionSetIndex].isMultiQty,
      })
      setAddOns([...addOnsCopy])
    } else {
      decreaseOptionQty(optionSetIndex, option, 1)
    }
  }

  const getSwitchStatus = (optionSetIndex, option) => addOns[optionSetIndex]? addOns[optionSetIndex].some((val) => val.addon_id == option.id): false

  const getIngredientSwitchStatus = (id) => !removedIngredients.some((val) => val.id == id)

  const handleIngredient = ({ event, ingredient_name, id }) => {
    if (event.target.checked) {
      const removedIndex = removedIngredients.findIndex((ingredient) => ingredient.id === id)
      setRemovedIngredients((items) => {
        items.splice(removedIndex, 1)
        return [...items]
      })
    } else {
      setRemovedIngredients((items) => {
        items.push({ id, ingredient_name })
        return [...items]
      })
    }
  }

  const getIngredientItems = () => {
    return (
      productOptions.ingredient &&
      productOptions.ingredient.map(({ ingredient_name, id }, index) => (
        <Box key={id}>
          <ListItem button>
            <Grid xs={10}>
              <Box className="product-menu-dropdown-list">
                <Typography className={backendclasses.productMenuFontColors} variant="h5">
                  {ingredient_name}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={2}>
              <Switch
                onChange={(event) => handleIngredient({ event, ingredient_name, id })}
                className={backendclasses.productSwitch}
                name="checkedA"
                inputProps={{ "aria-label": " checkbox" }}
                checked={getIngredientSwitchStatus(id)}
              />
            </Grid>
          </ListItem>
          {index < productOptions.ingredient.length - 1 ? <hr className="optionset-hr" /> : ""}
        </Box>
      ))
    )
  }

  const getIngredient = () => {
    return productOptions.ingredient ? (
      <Grid xs={12} className="addOnBg">
        <Grid item xs={12}>
          <List>
            <ListItem button onClick={() => handleOptionSetAccordian(0)}>
              <Grid xs={11}>
                <Box className="product-menu-dropdown-heading">
                  <Typography className={backendclasses.productMenuFontColors} variant="h5">
                    Ingredient
                  </Typography>
                  <Typography className={backendclasses.productMenuFontColors} variant="h6">
                    Un-check to remove
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={1}>
                {optionAccordianExpension[optionAccordianExpension.length - 1]?.isExpanded ? (
                  <ExpandLess className={backendclasses.expandBtn} />
                ) : (
                  <ExpandMore className={backendclasses.expandBtn} />
                )}
              </Grid>
            </ListItem>
            <Collapse in={optionAccordianExpension[optionAccordianExpension.length - 1]?.isExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {optionAccordianExpension[optionAccordianExpension.length - 1]?.isExpanded ? getIngredientItems() : <div></div>}
              </List>
            </Collapse>
          </List>
        </Grid>
        <Divider />
      </Grid>
    ) : (
      ""
    )
  }

  const optionsSelector = ({ option_sets, optionSetIndex, option }) => {
    if (option_sets.select_multiple === "on" && option_sets.enable_opt_qty === "on") {
      return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" xs={5}>
          <Grid xs={3}>
            <Box className="product-menu-dropdown-list">
              <Typography className={backendclasses.productMenuFontColors} variant="h5">
                {option.opt_price ? `+${option.opt_price}` : "+0"}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={9}>
            <ButtonGroup color="primary" aria-label="outlined primary button group" className="cart-menu-add-remove">
              <Button onClick={() => decreaseOptionQty(optionSetIndex, option, 1)}>
                <RemoveIcon className={backendclasses.productMenuAddRemove} fontSize="small" />
              </Button>
              <Button>
                <Typography className={backendclasses.productMenuFontColors} align="center" variant="h6">
                  {GetAddOnValue(optionSetIndex, option)}
                </Typography>
              </Button>
              <Button
                onClick={() =>
                  increaseOptionQty({
                    max_opt_required: option_sets.max_opt_required,
                    optionSetIndex,
                    option,
                  })
                }
              >
                {" "}
                <AddIcon fontSize="small" className={backendclasses.productMenuAddRemove} />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )
    } else if (option_sets.select_multiple === "on") {
      return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" xs={5}>
          <Grid xs={4}>
            <Box className="product-menu-dropdown-list">
              <Typography className={backendclasses.productMenuFontColors} variant="h5">
                {option.opt_price ? `+${option.opt_price}` : "+0"}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Switch
              onChange={(event) => handleMultiQty({ event, option_sets, optionSetIndex, option })}
              className={backendclasses.productSwitch}
              inputProps={{ "aria-label": " checkbox" }}
              checked={getSwitchStatus(optionSetIndex, option)}
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" xs={5}>
          <Grid xs={4}>
            <Box className="product-menu-dropdown-list">
              <Typography className={backendclasses.productMenuFontColors} variant="h5">
                {option.opt_price ? `+${option.opt_price}` : "+0"}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={4}>
            <Switch
              onChange={(event) => handleSingleQty({ event, option_sets, optionSetIndex, option })}
              className={backendclasses.productSwitch}
              name="checkedA"
              inputProps={{ "aria-label": " checkbox" }}
              checked={getSwitchStatus(optionSetIndex, option)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const getOptionsSetItems = ({ option_sets, optionSetIndex }) => {
    return (
      option_sets.optionset_options &&
      option_sets.optionset_options.map((option, index) => (
        <Box key={option.id}>
          <ListItem button className={classes.nested}>
            <Grid xs={5} sm={7}>
              <Box className="product-menu-dropdown-list">
                <Typography className={backendclasses.productMenuFontColors} variant="h5">
                  {option.opt_name}
                </Typography>
              </Box>
            </Grid>

            {optionsSelector({ option_sets, optionSetIndex, option })}
          </ListItem>
          {index < option_sets.optionset_options.length - 1 ? <hr className="optionset-hr" /> : ""}
        </Box>
      ))
    )
  }

  const getOptionsSetText = ({ option_set }) => {
    if (option_set.required === "on") {
      return `Select ${
        option_set.select_multiple === "on" ? `${option_set.max_opt_required ? "upto" + option_set.max_opt_required : ""} multiple` : "one"
      } (Required)`
    } else {
      return `Select ${
        option_set.select_multiple === "on" ? `${option_set.max_opt_required ? "upto" + option_set.max_opt_required : ""} multiple` : "one"
      } (Optional)`
    }
  }

  const body = productOptions.option_sets ? (
    productOptions.option_sets.map((option_set, index) => (
      <Grid key={option_set.id} xs={12} className="addOnBg">
        <Grid item xs={12}>
          <List>
            <ListItem button onClick={() => handleOptionSetAccordian(option_set.id)}>
              <Grid xs={11}>
                <Box className="product-menu-dropdown-heading">
                  <Typography className={backendclasses.productMenuFontColors} variant="h5">
                    {option_set.display_name ? option_set.display_name : option_set.name}
                    {/* {option_set.name
                      ? option_set.name
                      : option_set.display_name} */}
                  </Typography>

                  <Typography className={backendclasses.productMenuFontColors} variant="h6">
                    {getOptionsSetText({ option_set })}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={1}>
                {optionAccordianExpension[index]?.isExpanded ? (
                  <ExpandLess className={backendclasses.expandBtn} />
                ) : (
                  <ExpandMore className={backendclasses.expandBtn} />
                )}
              </Grid>
            </ListItem>
            <Collapse in={optionAccordianExpension[index]?.isExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {optionAccordianExpension[index]?.isExpanded ? (
                  getOptionsSetItems({
                    option_sets: option_set,
                    optionSetIndex: index,
                  })
                ) : (
                  <div></div>
                )}
              </List>
            </Collapse>
          </List>
        </Grid>
        <Divider />
      </Grid>
    ))
  ) : (
    <div></div>
  )

  return (
    <div>
      {body}
      {getIngredient()}
    </div>
  )
}

export default ProductOptions
