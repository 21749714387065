import { RESTAURANT_API_BASE_URL } from './endpoints';

export const post = async ({ url, baseURL, body }) => {
	const method = 'POST';
	const bodyString = JSON.stringify(body);
	const response = await fetch(`${baseURL || RESTAURANT_API_BASE_URL}${url}`, {
		body: bodyString,
		method,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});
	const jsonResponse = await response.json();
	if (!response.ok) {
		throw jsonResponse;
	}

	return jsonResponse;
};

export const put = async ({ url, body }) => {
	const method = 'PUT';
	const bodyString = JSON.stringify(body);
	return (
		await fetch(`${RESTAURANT_API_BASE_URL}${url}`, {
			body: bodyString,
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
	).json();
};

export const putFile = async ({ url, file }) => {
	const method = 'PUT';

	return await fetch(`${url}`, {
		body: file,
		method,
		headers: {
			crossDomain: true,
			// Accept: file.type,
			// 'Content-Type': file.type,
			// 'Content-Type': 'application/octet-stream',
			'Access-Control-Allow-Origin': '*',
		},
	});
};

export const get = async ({ url }) => {
	const method = 'GET';
	return (
		await fetch(`${RESTAURANT_API_BASE_URL}${url}`, {
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
		})
	).json();
};

export const deleteReq = async ({ url }) => {
	const method = 'DELETE';
	return (
		await fetch(`${RESTAURANT_API_BASE_URL}/${url}`, {
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
	).json();
};
