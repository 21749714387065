/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import "../../style/mainStyle.css";
import { priceLogicHandler } from "../../pages/home/components/priceHandler";
import {
  Grid,
  Typography,
  
  Divider,
  Box,
} from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import NavBar from "./NavBar";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyles } from "../../style/BackEndStyle";

const Header = () => {
  const [showNav, setShowNav] = useState(null);

  const handleClick = (event) => {
    setShowNav(event.currentTarget);
  };

  const handleClose = () => {
    setShowNav(null);
  };
  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();
  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <div>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <div className="logo-responsive">
                  <img
                    src={
                      "https://ramenmenu.com/staffmember/" +
                      data.top_nav_logo_image
                    }
                    alt="Logo"
                    width="280px"
                    height="90px"
                    onClick={() =>window.location.href=data.top_nav_logo_link}
                  />
                  {/* </a> */}
                </div>
              </Grid>
              <Grid item xs={12} lg={9}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    lg={8}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className="top-offer"
                  >
                    <NotificationsActiveIcon
                      fontSize="large"
                      className={classes.iconsBtn}
                    />
                    <Box className="announcement">
                      <Box className={classes.announcement}>
                        <Typography variant="h6">{data.top_nav_announcement}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={7} lg={4}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={8}>
                        <Box className="header-social-media-icons ">
                          <a
                            href={data.web_facebook_link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <FacebookIcon
                              className={classes.iconsBtn}
                              fontSize="large"
                            />
                          </a>
                          <a
                            href={data.web_instagram_link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <InstagramIcon
                              className={classes.iconsBtn}
                              fontSize="large"
                            />
                          </a>
                          <a
                            href={data.web_twitter_link}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <TwitterIcon
                              className={classes.iconsBtn}
                              fontSize="large"
                            />
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={12}>
                    <Divider
                      orientation="horizontal"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={5} lg={12}>
                    <NavBar></NavBar>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Header;
