import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Box, Divider, Modal } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { Grid, List, Typography } from "@material-ui/core";
import ProductOptions from "./ProductOptions";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#fff",
  boxShadow: 24,
  // p: 1,
};

export const ComboDeals = ({
  backendclasses,
  selectedProduct,
  classes,
  addOns,
  setAddOns,
  productPrice,
  setProductPrice,
  removedIngredients,
  setRemovedIngredients,
  setisValid,
  setComboTax
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [activeChoiceId, setActiveChoiceId] = useState(null);
  const [selectedChoice, setSelectedChoice] = useState(undefined);
  const [selectedComboDish, setSelectedComboDish] = useState(undefined);
  const [comboAddOns, setComboAddOns] = useState([]);
  const [comboRemovedIngredients, setComboRemovedIngredients] = useState([]);
  const [editMode, seEditMode] = useState(false);

  useEffect(() => {
    if(selectedComboDish){
    saveAddOns()
    }
  },[comboAddOns,comboRemovedIngredients])

  const handleOpen = ({ choice }) => {
    setOpen(true);
    setSelectedChoice(choice);
  };

  const saveAddOns = () => {
    const comboIndex = addOns.findIndex(addOn => addOn.dish.id === selectedComboDish.id && addOn.choice_id === activeChoiceId)
      if(comboIndex !== -1){
        setAddOns(prevState => {
          prevState[comboIndex] = {...prevState[comboIndex],comboAddOns: [...comboAddOns], removedIngredients: [...comboRemovedIngredients]}
          return [...prevState]
        })
      }else{
        const prevState = [...addOns]
        prevState.push({choice_id: activeChoiceId, dish: {...selectedComboDish}, comboAddOns: [...comboAddOns], removedIngredients: [...comboRemovedIngredients]})
        setAddOns([...prevState])
          // return [...prevState]
        // })
      }
  }

  const comboDishSelected = (dish) => {
    if(selectedComboDish){
      saveAddOns()
    }
    const comboIndex = addOns.findIndex(addOn => addOn.dish.id === dish.id && addOn.choice_id === selectedChoice.id)
  
    if(comboIndex !== -1){
        
        seEditMode(true)
        setComboAddOns([...addOns[comboIndex].comboAddOns])
        setComboRemovedIngredients([...addOns[comboIndex].removedIngredients])
      }else {
        const prevState = [...addOns]
        const previousComboDishIndex = addOns.findIndex(addOn => addOn.choice_id === selectedChoice?.id)
      
        if(previousComboDishIndex !== -1){
          const priceToRemove = prevState[previousComboDishIndex].comboAddOns.reduce((addOnArrAcc, addOnArr) => {
            return addOnArr.reduce((addOn_acc, addOn) => addOn_acc+(addOn.addon_opt_price * addOn.addon_qty),addOnArrAcc) 
          },0)
          setProductPrice(price => price -= priceToRemove)
          prevState.splice(previousComboDishIndex,1)
          // prevState[previousComboDishIndex] = {choice_id: activeChoiceId, dish: {...selectedComboDish}, comboAddOns: [...comboAddOns], removedIngredients: [...comboRemovedIngredients]}
          setAddOns([...prevState])
        }
        seEditMode(false)
        setComboAddOns([])
        setComboRemovedIngredients([])
      }
    setActiveChoiceId(selectedChoice?.id);
    setSelectedComboDish(dish);
    setOpen(false);
  };

  const updateComboDishTax = (taxes) => {
    const selectedComboIndex = selectedProduct.choice.findIndex(product => product.id === activeChoiceId)
    setComboTax(tax => {
      tax[selectedComboIndex] = taxes
      return [...tax]
    })
  }

  const getOptions = (choice) => {
    if(selectedComboDish && choice?.id === activeChoiceId && choice?.dishes?.some(dish => (dish.id === selectedComboDish.id))){
      return (
        <ProductOptions
          productPrice={productPrice}
          setProductPrice={setProductPrice}
          selectedProduct={selectedComboDish}
          backendclasses={backendclasses}
          classes={classes}
          editMode={editMode}
          addOns={comboAddOns}
          setAddOns={setComboAddOns}
          removedIngredients={comboRemovedIngredients}
          setRemovedIngredients={setComboRemovedIngredients}
          setisValid={setisValid}
          isComboProduct={true}
          updateComboDishTax={updateComboDishTax}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const body = (
    <div>
      <Box sx={style}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <ClearIcon
            onClick={handleClose}
            className={`${backendclasses.cartScreenPrimaryColor} custom-cart-screen-cross`}
          />
        </Grid>
        <Box className="combo-heading">
          <Typography
            align="center"
            className={backendclasses.cartScreenText}
            variant="h6"
          >
            Choose A Dish
          </Typography>
        </Box>
        <Divider />
        {selectedChoice &&
          selectedChoice.dishes.map((dishes) => (
            <div key={dishes.id}>
              <Box className="Combo-hover-item">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="combo-items"
                  xs={12}
                  onClick={() => {
                    comboDishSelected(dishes);
                  }}
                >
                  <Typography
                    className={backendclasses.cartScreenText}
                    variant="h5"
                   
                  >
                    {dishes.name}
                  </Typography>
                  <Typography
                    className={backendclasses.cartScreenText}
                    variant="h6"
                  >
                    {/* {dishes.price} */}
                  </Typography>
                </Grid>
              </Box>
              <Divider />
            </div>
          ))}
      </Box>
    </div>
  );

  return (
    <div>
      <Grid xs={12} className="addOnBg">
        <Grid item xs={12}>
          <List>
            {selectedProduct.choice &&
              selectedProduct.choice.map((choice) => (
                <div key={choice.id}>
                  <ListItem button onClick={() => handleOpen({ choice })}>
                    <Grid xs={11}>
                      <Box className="product-menu-dropdown-heading">
                        <Typography
                          className={backendclasses.productMenuFontColors}
                          variant="h5"
                        >
                          {choice.choice_name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={1}>
                      <ExpandMore className={backendclasses.expandBtn} />
                    </Grid>
                  </ListItem>
                  <Grid xs={12}>
                    {  
                      getOptions(choice)
                    }
                  </Grid>
                </div>
              ))}
          </List>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {open ? body : <div></div>}
      </Modal>
    </div>
  );
};
