import { makeStyles } from "@material-ui/core/styles";

const useStyles = (styles) =>
    makeStyles({
        body: {
            padding: 0,
            margin: 0,
            fontFamily: "Arial",
        },
        bannerTitle: {
            color: "red",
            "& h1": {
                color: styles ? styles.web_header_text_color : "000000",
                fontSize: "28px",
                paddingTop: "25px",
                paddingBottom: "10px",
                fontFamily: styles ? styles.web_heading_font : "arial",
            },
            "& h2": {
                color: styles ? styles.web_header_text_color : "000000",
                fontSize: "24px",

                fontFamily: styles ? styles.web_heading_font : "arial",
            },
        },
        heroBtn: {
            backgroundColor: styles ? styles.web_primary_color : "#89b649",
            width: "200px",
            height: "60px",
            "&:hover": {
                backgroundColor: styles ? `${styles.web_primary_color} !important` : "#89b649",
                width: "190px !important",
                height: "55px",
                TransitionEvent: "2s",
            }


        },

        announcement: {
            color: styles ? styles.top_nav_text_color : "#89b649",
            fontFamily: styles ? styles.web_normal_font : "arial",

        },
        iconsBtn: {
            fill: styles ? styles.web_primary_color : "#89b649",
        },
        footerTheme: {
            backgroundColor: styles ? styles.footer_background_color : "#cacad69c",
            padding: "35px",
            margin: "25px 0px 0px ",
            paddingBottom: "0px",

        },
        footerText: {
            color: styles ? styles.footer_text_color : "black",
            fontFamily: styles ? styles.web_normal_font : "arial",

        },
        Bgcolor: {
            background: styles ? styles.web_background_color : "none",
        },
        footerBg: {
            backgroundColor: styles ? styles.web_background_color : "none",
        },
        contactBox: {
            background: styles ? styles.web_box_and_popup_color : "#89b649",
        },
        msgBox: {
            border: "2px solid",
            borderColor: styles ? styles.web_primary_color : "#89b649",
            padding: "30px",
        },
        contactBtn: {
            backgroundColor: styles ? styles.web_primary_color : "#89b649",
            width: "160px",
            height: "50px",
            "&:hover": {
                backgroundColor: styles ? `${styles.web_primary_color} !important` : "#89b649",
                width: "150px !important",
                height: "50px",
                TransitionEvent: "2s",
                color: "white",
            }
            
        },
        msgTitle: {
            color: styles ? styles.web_primary_text_color : "black",
            fontFamily: styles ? styles.web_heading_font : "arial",

        },
        popupText: {
            color: styles ? styles.web_box_and_popup_text_color : "black",
            fontFamily: styles ? styles.web_normal_font : "arial",

        },
        popupHeading: {
            color: styles ? styles.web_box_and_popup_text_color : "black",
            fontFamily: styles ? styles.web_heading_font : "arial",

        },
        popupHeadingCustom: {
            color: styles ? styles.web_primary_color : "black",
            fontFamily: styles ? styles.web_heading_font : "arial",

        },
        servicesIcons: {
            fill: styles ? styles.web_primary_color : "#89b649",
            width: '50px',
            height: '60px',
            paddingTop: '5px',
        },
        servicesBg: {
            backgroundColor: styles ? styles.web_box_and_popup_color : "#89b649",
            height: '426px',
            maxHeight:"520px",
        },
        productBg: {
            backgroundColor: styles ? styles.web_box_and_popup_color : "#89b649",
            height: "130px",
        },
        productRoot: {
            maxWidth: 279,
            minWidth:279,
            
            
            "&:hover": {
            maxWidth: 280,
            minWidth:290,
            }
            
        },
        productMedia: {
            height: 200,
        },
        productText: {
            color: styles ? styles.web_primary_text_color : "#89b649",
            fontWeight: "400",
            fontSize: "20px",
            fontFamily: styles ? styles.web_heading_font : "arial",
            margin: "0",
            paddingBottom: "1px",


        },
        root: {
            flexGrow: 1,
        },

        progressLabel: {
            position: "absolute",
            height: "100%",
            zIndex: 1,
            maxHeight: "45px", // borderlinearprogress root.height
            textAlign: "center",
            display: "flex",
            alignItems: "center",

            "& span": {
                padding: "10px",
                color: "black",
            },
        },
        headerTitle: {
            color: styles ? styles.web_header_text_color : "#89b649",
            fontFamily: styles ? styles.web_heading_font : "arial",

        },
        progressBar: {
            fill: 'red',
        },
        linePro: {
            fill: 'red',
        },
        tabBtn: {
            color: styles ? styles.web_primary_color : "#89b649",

        },
        activeTabBtn: {
            // backgroundColor: "rgba(0, 0, 0, 0.04)",

        },
        menuButton: {
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            size: "18px",
            marginLeft: "38px",
            color: styles ? styles.web_primary_color : "#89b649",


        },


        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: styles ? styles.web_primary_color : "#89b649",
                outline: '1px solid slategrey'
            }
            
        },
        contactMsg: {
            width: "100%",
            fontSize: "18px",
            borderRadius: "5px",
            maxHeight: "150px",
            maxWidth: "100%",
            minWidth: "100%",
            minHeight: "150px",
            background: styles ? styles.web_background_color : "none",
        },
        primaryText: {
            color: styles ? styles.web_primary_text_color : "#89b649",

            fontFamily: styles ? styles.web_heading_font : "arial",
        },
        normalFont: {
            fontFamily: styles ? styles.web_normal_font : "arial",

        },
        footerTitle: {
            backgroundColor: styles ? styles.web_primary_color : "black",
            padding: "0px 20px",
            borderRadius: "30px",
            color: styles ? styles.footer_text_color : "white",
        },
        productMenuFontColors: {
            color: styles ? styles.web_box_and_popup_text_color : "black",
            fontFamily:"Lato,Verdana,Helvetica,sans-serif",
            "& h2":{
                color: styles ? styles.web_box_and_popup_text_color : "black",

            }
        },
        
        modalCross:{
            backgroundColor: styles ? styles.web_primary_color:"black",
            color  : "white",
            position: "relative",
            borderRadius:"50px",
            padding: "4px",

        },
        comboCossbtn:{
            backgroundColor:"black !important",
           
        },
        productModalBtn:{
            width: "120px",
            height: "40px",
            border:`1px solid ${styles ? styles.web_primary_color : "#89b649"} `,
            color: styles ? styles.web_primary_color : "#89b649",
            "&:hover": {
                backgroundColor: styles ? styles.web_primary_color +"!important" : "#89b649",
                width: "115px !important",
                height: "35px",
                TransitionEvent: "2s",
                color: "white",
            }
         

        },
        productMenuAddRemove:{
            fill: styles ? styles.web_box_and_popup_text_color : "#89b649",
            
        },
        productSwitch:{
          
            "& span":{
                color: styles ? styles.web_box_and_popup_text_color:"black",
                "&:hover": {
                    backgroundColor: styles ? `${styles.web_box_and_popup_text_color}!important`:"black",
                    height: "5px",
                    width: "5px",
                    marginTop:"6px",
                    marginLeft:"5px",

                },
               
            }
        },
        expandBtn:{
            color: styles ? styles.web_box_and_popup_text_color:"black",
        }
        ,
        cartScreenPrimaryColor:{
            backgroundColor:styles ? styles.web_primary_color : "",
        },
        orderPlacedBtn:{
            backgroundColor:styles ? styles.web_primary_color : "",
            width: "90%",
            "&:hover": {
                backgroundColor: styles ? `${styles.web_primary_color}!important`:"black",
            }
        },
        editRemoveBtn:{
          color: styles ? styles.web_primary_color : "",   
        },
        cartScreenText:{
            color: styles ? styles.web_box_and_popup_text_color:"black",
        },
     

    });

export { useStyles };