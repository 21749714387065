import React, { useState } from "react";
import { ThemeContext } from "../context/ThemeContext";

export const TermsConditions = () => {
  
  const [styles, setStyles] = useState(undefined);
  
  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return <div dangerouslySetInnerHTML={{__html: data.terms_conditions}}></div>;
      }}
    </ThemeContext.Consumer>
  );
};
