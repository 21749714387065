import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Footer from "../../components/Footer";
import { ThemeContext } from "../../context/ThemeContext";
import { useStyles } from "../../style/BackEndStyle";
import { getLocationHours } from "../../api";
import Banner from "../home/components/Banner";
import { APP_BASE_URL } from "../../api/endpoints";
import moment from "moment";
import { contactUs } from "../../api";
import Alert from "@material-ui/lab/Alert";

// import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3"

// const YourReCaptchaComponent = ({ contactBtn, onSubmit }) => {
//   const { executeRecaptcha } = useGoogleReCaptcha()

//   // Create an event handler so you can call the verification on button click event or form submit
//   const handleReCaptchaVerify = useCallback(async () => {
//     if (!executeRecaptcha) {
//       console.log("Execute recaptcha not yet available")
//     }

//     const token = await executeRecaptcha("yourAction")
//     if (token) {
//       onSubmit()
//     }
//     // Do whatever you want with the token
//   }, [executeRecaptcha])

//   return (
//     <div></div>
//   )
// }

const ContactUs = () => {
  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();
  const [locationOpeningHours, setLocationOpeningHours] = useState(undefined);
  const [specialHours, setSpecialHours] = useState(undefined);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [valid, setValid] = useState({
    firstName: true,
    email: true,
    message: true,
  });
  const [isFormValid, setIsFormValid] = useState(true);
  const [alert, setAlert] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    getLocationHoursData();
  }, []);

  const getLocationHoursData = async () => {
    const {
      data: { location_opening_hour, special_hours },
    } = await getLocationHours();
    setLocationOpeningHours(location_opening_hour);
    setSpecialHours(special_hours);
  };

  const getLocationOpenAndCloseTime = (
    timeFormat,
    open_time,
    close_time,
    location
  ) => {
    if (location === "on") {
      return "24Hrs";
    } else if (location === "" && (close_time === "" || open_time === "")) {
      return "invlid open & close time";
    } else {
      let final_format = "";
      if (timeFormat === "12_hours") {
        final_format = "hh:mm A";
      } else {
        final_format = "HH:mm";
      }
      let OPEN_TIME = new moment(open_time, "h:m").format(final_format);
      let CLOSE_TIME = new moment(close_time, "h:m").format(final_format);
      return `${OPEN_TIME} - ${CLOSE_TIME}`;
    }
  };

  const onSubmit = async () => {
    form.first_name ? (valid.firstName = true) : (valid.firstName = false);
    form.email ? (valid.email = true) : (valid.email = false);
    form.message ? (valid.message = true) : (valid.message = false);

    if (
      valid.firstName === true &&
      valid.email === true &&
      valid.message === true
    ) {
      // const res = await contactUs({ ...form });
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 4000);
      window.location.reload();
    } else {
      setIsValid(true);
      setTimeout(() => {
        setIsValid(false);
      }, 4000);
    }
  };

  const changeHandler = (event) => {
    const val = { ...form };
    val[event.target.name] = event.target.value;
    setForm({ ...val });
  };

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Box>
            <Box className={classes.Bgcolor}>
              <Banner />
              <Container>
                <Box className="ContactUs">
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={5}>
                      <Box className={classes.contactBox}>
                        <Box className="contactText">
                          <Typography
                            className={classes.popupHeading}
                            align="left"
                            variant="h4"
                          >
                            OPENING TIMINGS
                          </Typography>
                          <Box className="contect-opening-time">
                            {locationOpeningHours &&
                              locationOpeningHours.map((location) => (
                                <Box key={location.id}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      className={classes.popupText}
                                      align="left"
                                      variant="h6"
                                    >
                                      {location.day}
                                    </Typography>
                                    <Typography
                                      className={classes.popupText}
                                      align="right"
                                      variant="h6"
                                    >
                                      {getLocationOpenAndCloseTime(
                                        data.general_timeformat,
                                        location.open_time,
                                        location.close_time,
                                        location["24_hours"]
                                      )}
                                    </Typography>
                                  </Grid>

                                  <Divider />
                                </Box>
                              ))}
                          </Box>

                          <Box className="contactText2">
                            <Typography
                              className={classes.popupHeading}
                              align="left"
                              variant="h4"
                            >
                              Contact US
                            </Typography>
                          </Box>
                          <Grid
                            container
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                          >
                            <CallIcon className={classes.iconsBtn} />
                            <Typography
                              className={classes.popupText}
                              variant="h6"
                            >
                              {data.location_phone_no}
                            </Typography>
                          </Grid>
                          <Box className="terms-condition">
                            <Grid
                              container
                              direction="row"
                              justifyContent="left"
                              alignItems="center"
                            >
                              <DescriptionIcon
                                className={classes.iconsBtn}
                                onClick={() =>
                                  window.open(
                                    `${APP_BASE_URL}TermsConditions`,
                                    "_blank"
                                  )
                                }
                              />
                              <Typography
                                onClick={() =>
                                  window.open(
                                    `${APP_BASE_URL}TermsConditions`,
                                    "_blank"
                                  )
                                }
                                className={classes.popupText}
                                variant="h6"
                              >
                                Terms & Conditions
                              </Typography>
                            </Grid>
                          </Box>
                          <Grid
                            container
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                          >
                            <LocationOnIcon className={classes.iconsBtn} />{" "}
                            <Typography
                              align="left"
                              className={classes.popupText}
                              variant="h6"
                            >
                              {data.location_address}
                            </Typography>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={7}>
                      {alert ? (
                        <div>
                          <Alert severity="success">
                            Your Message has been sand successfuly
                          </Alert>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {isValid ? (
                        <div>
                          <Alert severity="success">
                            Please fill all compulsory fields
                          </Alert>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <Box className="sandMsg">
                        <Box className={classes.msgBox}>
                          <Box className="contactUsForm">
                            <Typography
                              className={classes.msgTitle}
                              align="left"
                              variant="h4"
                            >
                              HOW CAN WE HELP YOU ?
                            </Typography>
                          </Box>

                          <Box className="contactFields">
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs={12} md={6}>
                                {valid.firstName ? (
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="First Name"
                                    name="first_name"
                                    defaultValue=""
                                    variant="outlined"
                                    onChange={changeHandler}
                                  />
                                ) : (
                                  <TextField
                                    error
                                    fullWidth
                                    id="outlined-basic"
                                    label="First Name"
                                    name="first_name"
                                    defaultValue=""
                                    variant="outlined"
                                    onChange={changeHandler}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Last Name"
                                  variant="outlined"
                                  name="last_name"
                                  onChange={changeHandler}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {valid.email ? (
                                  <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    onChange={changeHandler}
                                  />
                                ) : (
                                  <TextField
                                    error
                                    fullWidth
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    name="email"
                                    onChange={changeHandler}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Subject"
                                  variant="outlined"
                                  name="subject"
                                  onChange={changeHandler}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                {valid.message ? (
                                  <TextareaAutosize
                                    className={classes.contactMsg}
                                    aria-label="minimum height"
                                    minRows={4}
                                    placeholder="Message"
                                    name="message"
                                    onChange={changeHandler}
                                  />
                                ) : (
                                  <TextareaAutosize
                                    style={{ borderColor: "red" }}
                                    className={classes.contactMsg}
                                    aria-label="minimum height"
                                    minRows={4}
                                    placeholder="Message"
                                    name="message"
                                    onChange={changeHandler}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                          <Button
                            className={classes.contactBtn}
                            type="submit"
                            onClick={onSubmit}
                          >
                            Submit
                          </Button>
                          {/* <Box className="contactUsBtn"> */}
                          {/* <GoogleReCaptchaProvider reCaptchaKey="6LeVyo4cAAAAALzYiyw2dcYlnWL3JBDffH0hl5We">
                            <YourReCaptchaComponent contactBtn={classes.contactBtn} onSubmit={onSubmit} />
                          </GoogleReCaptchaProvider> */}
                          {/* </Box> */}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>

              <Footer />
            </Box>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default ContactUs;
