/* eslint-disable jsx-a11y/alt-text */
import {
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DescriptionIcon from "@material-ui/icons/Description";
import { ThemeContext } from "../context/ThemeContext";
import { useStyles } from "../style/BackEndStyle";
import { APP_BASE_URL } from "../api/endpoints";
const Footer = () => {
  const [styles, setStyles] = useState(undefined);
  const classes = useStyles(styles)();

  return (
    <ThemeContext.Consumer>
      {(data) => {
        setStyles(data);
        return (
          <Box>
            <Box className={classes.footerTheme}>
              <Container style={{ marginTop: "-60px" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Box className="footerTitle">
                      <Typography variant="h5">
                        <span className={classes.footerTitle}>Contact Us</span>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xm={12} lg={3}>
                    <Box className="footer-social-icons">
                      <a
                        href={data.web_facebook_link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FacebookIcon
                          className={classes.iconsBtn}
                          fontSize="large"
                        />
                      </a>
                      <a
                        href={data.web_instagram_link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <InstagramIcon
                          className={classes.iconsBtn}
                          fontSize="large"
                        />
                      </a>
                      <a
                        href={data.web_twitter_link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <TwitterIcon
                          className={classes.iconsBtn}
                          fontSize="large"
                        />
                      </a>
                    </Box>
                  </Grid>

                  <Grid item xm={12} lg={3}>
                    <Box className="footerContact">
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CallIcon
                          className={classes.iconsBtn}
                          fontSize="large"
                        />
                        <Typography className={classes.footerText} variant="h5">
                          {data.location_phone_no}{" "}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xm={12} lg={3}>
                    <Box className="footerContact">
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box className="terms-condition" >
                          <DescriptionIcon
                            onClick={() =>
                              window.open(
                                `TermsConditions`,
                                "_blank"
                              )
                            }
                            className={classes.iconsBtn}
                            fontSize="large"
                          />

                          <Typography
                            onClick={() =>
                              window.open(
                                `${APP_BASE_URL}TermsConditions`,
                                "_blank"
                              )
                            }
                            
                            className={classes.footerText}
                            variant="h5"
                          >
                            Terms & Conditions
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xm={12} lg={3}>
                    <Box className="footerContact">
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <LocationOnIcon
                          className={classes.iconsBtn}
                          fontSize="large"
                        />
                        <Typography className={classes.footerText} variant="h5">
                          {data.location_address}{" "}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        );
      }}
    </ThemeContext.Consumer>
  );
};
export default Footer;
