/* eslint-disable jsx-a11y/alt-text */
import {
  Container,
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  TextareaAutosize,
} from "@material-ui/core";
import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/header/Header";
import { useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  TimePicker,
  

  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const Reservation = () => {
  const [selectedDate, handleDateChange] = useState(new Date());
  return (
    <Box>
      <Box
        style={{
          backgroundImage: `url(http://themeforest.kamleshyadav.net/script/foodiee/themeassets/images/bread_bg.jpg)`,
          backgroundSize: "cover",
        }}
      >
        <Box className="about-bg">
          <Header />
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <Box className="about-text">
                  <Typography variant="h1">Reservation</Typography>
                </Box>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid xs={3}>
                    {/* <Box className="btn-style">
                      <Box className="About-text2">
                        <Typography variant="h4">Home > About</Typography>
                      </Box>
                    </Box> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box className="reservation">
        <Container>
          <Grid container>
            <Grid item xm={12} md={8}>
              <Box className="reservation-from">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="reservation-from-text">
                      <Typography variant="h6">
                        Please Submit your reservation details and we will
                        contact you to confirm your booking
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="E-Mail"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="Contact No."
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <TextField
                      id="outlined-basic"
                      label="People"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                       
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      style={{
                        width: "98%",
                        fontSize: "18px",
                        borderRadius: "5px",
                      }}
                      aria-label="minimum height"
                      minRows={6}
                      placeholder="Message"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      style={{
                        backgroundColor: "#89b649",
                        color: "white",
                        width: "180px",
                        height: "50px",
                        borderRadius: "15px 0px",
                      }}
                      variant="outlined"
                    >
                      BOOK NOW
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                style={{
                  margin: "25px",
                  border: "2px solid #89b649",
                  borderRadius: "60px 0px",
                  width: "88%",
                }}
                src="http://themeforest.kamleshyadav.net/script/foodiee/uploads/website_images/book_img.jpg"
                width="100%"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};
export default Reservation;
